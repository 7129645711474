:root {
  --white: #ffffff;
  --MRTNavy: #000033;
  --MRTGreen: #29B494;
  --lightBlue: #F4FAFC;
  --lightGreen: #E7F6F3;
  --lightGrey: #EDEDED;
  --SuperLightGrey: #F5F5F5;
  --MediumGrey: #C1C1C1;
  --DarkGrey: #595959;
  --Black: #171717;
  --Red: #A90000;
  --Yellow: #FDC602;
  --header: 65px;
  --footer: 75px;
}


$MRTNavy: var(--MRTNavy);
$MRTGreen:  var(--MRTGreen);
$lightBlue:  var(--lightBlue);
$lightGreen:  var(--lightGreen);
$lightYellow: transparentize(#FDC602, .9);
$White:  var(--white);
$SuperLightGrey:  var(--SuperLightGrey);
$MediumGrey:  var(--MediumGrey);
$DarkGrey:  var(--DarkGrey);
$Black:  var(--Black);
$Red:  var(--Red);
$Yellow:  var(--Yellow);
$LightGrey: var(--lightGrey);


$sidebar-width: 248px;
$font-size-base: 16px;
$font-lead-base: 16px;


$font-weight-400: 400;
$font-weight-600: 600;
$font-weight-700: 700;
