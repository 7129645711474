.grid-table {
  padding-left: 1rem;
  padding-right: 1rem;
}

// TODO: Verify all mdc class names.
.preview-table {
  &.mat-mdc-table {
    .mat-mdc-header-row {
      height: 40px;

      .mat-mdc-header-cell {
        padding: 0;
      }
    }

    .mat-mdc-row {
      height: 40px;

      .mat-mdc-cell {
        padding: 0;
      }
    }
  }
}


.mat-mdc-table {
  .mat-mdc-row {
    height: 64px;
    font-size: 16px;
    line-height: 1.5;
  }

  .mat-mdc-header-row {
    font-size: 16px;
    line-height: 1.5;
    padding-top: 2px !important;
    padding-bottom: 2px !important;

    .mat-sort-header-content {
      font-size: 14px;
      line-height: 1.75;
      font-weight: 600;
      height: 32px;
      color: var(--black);
    }
  }
}


.table-responsive {
  min-height: 0.01%;
  overflow-x: auto;

  width: 100%;
  margin-bottom: 15px;
  overflow-y: hidden;
}


.responsive-table {
  & table,
  thead,
  tbody,
  th {
    display: block;
  }

  & thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;

    @media (min-width: 1024px) {
      position: static;
      top: auto;
      left: auto;
    }
  }

  & table {
    @media (min-width: 1024px) {
      display: table;
    }
  }

  & thead {
    @media (min-width: 1024px) {
      display: table-header-group;
    }
  }

  & tbody {
    @media (min-width: 1024px) {
      display: table-row-group;
    }
  }

  & tbody tr {
    display: table;
    width: 100%;
    // box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    //   0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    margin: 20px 0;

    @media (min-width: 1024px) {
      width: auto;
      display: table-row;
      box-shadow: none;
      margin: 0;
      border: none;
    }

    // &:nth-of-type(odd) {
    //   background: #eee;
    //   @media (min-width:1024px) {
    //     background: none;
    //   }
    // }
  }
  & th.mat-mdc-header-cell:last-of-type,
  td.mat-mdc-cell:last-of-type,
  td.mat-mdc-footer-cell:last-of-type {
    padding-right: 0 !important;
  }

  & td {
    position: relative;
    padding-left: 55% !important;
    height: 100%;
    display: flex;
    align-items: center;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    border-bottom-width: 0 !important;
    border-right: 1px solid #ddd;
    border-left: 1px solid #ddd;
    padding-right: 15px !important;

    &:first-child {
      border-top: 1px solid #ddd;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      padding-top: 10px !important;
      @media (min-width: 1024px) {
        border-top: none;
        border-top-left-radius: none;
        border-top-right-radius: none;
        padding-top: 0px !important;
      }
    }
    &:last-child {
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;
      border-bottom: 1px solid #ddd !important;
      padding-bottom: 10px !important;
      @media (min-width: 1024px) {
        border-bottom: none;
        border-bottom-left-radius: none;
        border-bottom-right-radius: none;
        padding-bottom: 0px !important;
      }
    }

    @media (min-width: 1024px) {
      display: table-cell;
      padding-left: 0 !important;
      padding-top: 0px !important;
      padding-bottom: 0px !important;
      border-bottom-width: 1px !important;
      border-right: none;
      border-left: none;
      height: 64px;
    }

    &::before {
      position: absolute;
      font-size: 13px !important;
      left: 10px;
      width: 45%;
      padding-right: 10px;
      white-space: nowrap;

      @media (min-width: 1024px) {
        font-size: 16px !important;
        content: none !important;
      }
    }

    &:nth-of-type(1):before {
      content: 'Start Date';
    }
    &:nth-of-type(2):before {
      content: 'Training';
    }
    &:nth-of-type(3):before {
      content: 'Status';
    }
    &:nth-of-type(4):before {
      content: 'Certificate';
    }
    &:nth-of-type(5):before {
      content: 'Issue Date';
    }
    &:nth-of-type(6):before {
      content: 'Expire Date';
    }
    &:nth-of-type(7):before {
      content: 'Retire Date';
    }
  }
  & th {
    @media (min-width: 1024px) {
      display: table-cell;
    }
  }
}

th.mat-mdc-header-cell:last-of-type,
td.mat-mdc-cell:last-of-type,
td.mat-mdc-footer-cell:last-of-type {
  padding-right: 0 !important;
}

.table-responsive-groups {
  & table,
  thead,
  tbody,
  th {
    display: block;
  }

  & th {
    @media (min-width: 1024px) {
      text-align: left !important;
    }
  }
  & th.mat-mdc-header-cell:first-of-type {
    padding-left: 0 !important;
  }

  & td {
    @media (min-width: 1024px) {
      width: 20% !important;
    }
  }

  & .mat-column-actions {
    text-align: right !important;
    padding-right: 0px !important;

    @media (min-width: 1024px) {
      padding-right: 24px !important;
    }
  }

  & thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;

    @media (min-width: 1024px) {
      position: static;
      top: auto;
      left: auto;
    }
  }

  & table {
    @media (min-width: 1024px) {
      display: table;
    }
  }

  & thead {
    @media (min-width: 1024px) {
      display: table-header-group;
    }
  }

  & tbody {
    @media (min-width: 1024px) {
      display: table-row-group;
    }
  }

  & tbody tr {
    display: table;
    width: 100%;
    // box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    //   0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    margin: 20px 0;

    @media (min-width: 1024px) {
      width: auto;
      display: table-row;
      box-shadow: none;
      margin: 0;
      border: none;
      font-size: 16px !important;
    }

    // &:nth-of-type(odd) {
    //   background: #eee;
    //   @media (min-width:1024px) {
    //     background: none;
    //   }
    // }
  }
  & th.mat-mdc-header-cell:last-of-type,
  td.mat-mdc-footer-cell:last-of-type {
    padding-right: 0 !important;
  }

  & td,
  & th {
    font-size: 13px !important;

    @media (min-width: 1024px) {
      font-size: 16px !important;
    }
  }

  & td {
    position: relative;
    padding-left: 55% !important;
    height: 100%;
    display: flex;
    align-items: center;
    padding-top: 5px !important;
    padding-bottom: 15px !important;
    border-bottom-width: 0 !important;
    border-right: 1px solid #ddd;
    border-left: 1px solid #ddd;
    padding-right: 15px !important;
    word-break: break-word;

    &:first-child {
      border-top: 1px solid #ddd;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      padding-top: 10px !important;
      @media (min-width: 1024px) {
        border-top: none;
        border-top-left-radius: none;
        border-top-right-radius: none;
        padding-top: 0px !important;
      }
    }
    &:last-child {
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;
      border-bottom: 1px solid #ddd !important;
      padding-bottom: 10px !important;
      @media (min-width: 1024px) {
        border-bottom: none;
        border-bottom-left-radius: none;
        border-bottom-right-radius: none;
        padding-bottom: 0px !important;
      }
    }

    & p {
      font-size: 13px !important;

      @media (min-width: 1024px) {
        font-size: 16px !important;
      }
    }

    & a {
      font-size: 13px !important;

      @media (min-width: 1024px) {
        font-size: 16px !important;
      }
    }

    @media (min-width: 1024px) {
      display: table-cell;
      padding-left: 0 !important;
      padding-top: 0px !important;
      padding-bottom: 0px !important;
      border-bottom-width: 1px !important;
      border-right: none;
      border-left: none;
      height: 64px;
    }

    &::before {
      position: absolute;
      font-size: 13px !important;
      left: 10px;
      width: 45%;
      padding-right: 10px;
      padding-bottom: 5px;

      @media (min-width: 1024px) {
        font-size: 16px !important;
        content: none !important;
      }
    }

    &:nth-of-type(1):before {
      content: 'Group Name';
    }
    &:nth-of-type(2):before {
      content: 'Trainings';
    }
    &:nth-of-type(3):before {
      content: 'Number of Learners';
    }
    &:nth-of-type(4):before {
      content: 'Status';
    }
    &:nth-of-type(5):before {
      content: ' ';
    }
  }
  & th {
    @media (min-width: 1024px) {
      display: table-cell;
    }
  }
}

.table-responsive-users {
  & table,
  thead,
  tbody,
  th {
    display: block;
  }

  & th {
    @media (min-width: 1024px) {
      text-align: left !important;
    }
  }
  & th.mat-mdc-header-cell:first-of-type {
    padding-left: 0 !important;
  }

  & td {
    @media (min-width: 1024px) {
      width: 14% !important;
    }
  }

  & .mat-column-actions {
    text-align: right !important;
    padding-right: 0px !important;

    @media (min-width: 1024px) {
      padding-right: 24px !important;
    }
  }

  & thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;

    @media (min-width: 1024px) {
      position: static;
      top: auto;
      left: auto;
    }
  }

  & table {
    @media (min-width: 1024px) {
      display: table;
    }
  }

  & thead {
    @media (min-width: 1024px) {
      display: table-header-group;
    }
  }

  & tbody {
    @media (min-width: 1024px) {
      display: table-row-group;
    }
  }

  & tbody tr {
    display: table;
    width: 100%;
    // box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    //   0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    margin: 20px 0;

    @media (min-width: 1024px) {
      width: auto;
      display: table-row;
      box-shadow: none;
      margin: 0;
      border: none;
      font-size: 16px !important;
    }

    // &:nth-of-type(odd) {
    //   background: #eee;
    //    @media (min-width: 1024px) {
    //     background: none;
    //   }
    // }
  }
  & th.mat-mdc-header-cell:last-of-type,
  td.mat-mdc-footer-cell:last-of-type {
    padding-right: 0 !important;
  }

  & td,
  & th {
    font-size: 13px !important;

    @media (min-width: 1024px) {
      font-size: 16px !important;
    }
  }

  & td {
    position: relative;
    padding-left: 55% !important;
    height: 100%;
    display: flex;
    align-items: center;
    padding-top: 5px !important;
    padding-bottom: 15px !important;
    border-bottom-width: 0 !important;
    border-right: 1px solid #ddd;
    border-left: 1px solid #ddd;
    padding-right: 15px !important;
    word-break: break-word;

    &:first-child {
      border-top: 1px solid #ddd;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      padding-top: 10px !important;
      @media (min-width: 1024px) {
        border-top: none;
        border-top-left-radius: none;
        border-top-right-radius: none;
        padding-top: 0px !important;
      }
    }
    &:last-child {
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;
      border-bottom: 1px solid #ddd !important;
      padding-bottom: 10px !important;
      @media (min-width: 1024px) {
        border-bottom: none;
        border-bottom-left-radius: none;
        border-bottom-right-radius: none;
        padding-bottom: 0px !important;
      }
    }

    & p {
      font-size: 13px !important;

      @media (min-width: 1024px) {
        font-size: 16px !important;
      }
    }

    & a {
      font-size: 13px !important;

      @media (min-width: 1024px) {
        font-size: 16px !important;
      }
    }

    @media (min-width: 1024px) {
      display: table-cell;
      padding-left: 0 !important;
      padding-top: 0px !important;
      padding-bottom: 0px !important;
      border-bottom-width: 1px !important;
      border-right: none;
      border-left: none;
      height: 64px;
    }

    &::before {
      position: absolute;
      font-size: 13px !important;
      left: 10px;
      width: 45%;
      padding-right: 10px;
      padding-bottom: 5px;

      @media (min-width: 1024px) {
        font-size: 16px !important;
        content: none !important;
      }
    }

    &:nth-of-type(1):before {
      content: 'First Name';
    }
    &:nth-of-type(2):before {
      content: 'Last Name';
    }
    &:nth-of-type(3):before {
      content: 'Email Address';
    }
    &:nth-of-type(4):before {
      content: 'Associated Date';
    }
    &:nth-of-type(5):before {
      content: 'Groups';
    }
    &:nth-of-type(6):before {
      content: 'Roles';
    }
    &:nth-of-type(7):before {
      content: 'Status';
    }
    &:nth-of-type(8):before {
      content: ' ';
    }
  }
  & th {
    @media (min-width: 1024px) {
      display: table-cell;
    }
  }
}


.seperate-header-table {
  .expanded-border {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }
  .mat-column-header-row-first-group,
  .mat-column-header-row-second-group,
  .mat-column-header-row-third-group,
  .mat-column-header-row-fifth-group,
  .mat-column-header-row-sixth-group {
    border-bottom-color: transparent !important;
  }
  .mat-column-header-row-fourth-group {
    font-size: 14px !important;
    font-weight: 600 !important;
    text-align: center !important;
  }

  & .mat-mdc-header-cell.mat-column-courseName,
  .mat-header-cell.mat-column-trainingType,
  .mat-header-cell.mat-column-state,
  .mat-header-cell.mat-column-registrationCount,
  .mat-header-cell.mat-column-certsIssuedCount {
    transform: translateY(-28px);
    width: 12.5% !important;
  }
  & .mat-column-header-row-first-group,
  .mat-mdc-header-cell.mat-column-courseName,
  .mat-mdc-cell.mat-column-courseName {
    padding-left: 0 !important;
  }

  & .mat-mdc-header-cell.mat-column-courseName,
  .mat-header-cell.mat-column-trainingType,
  .mat-header-cell.mat-column-state,
  .mat-header-cell.mat-column-registrationCount,
  .mat-header-cell.mat-column-certsIssuedCount {
    font-size: 14px !important;
    font-weight: 600 !important;
    text-align: center !important;
  }

  & .mat-mdc-header-cell.mat-column-completeCount,
  .mat-header-cell.mat-column-inProgressCount,
  .mat-header-cell.mat-column-notStartedCount {
    font-size: 14px !important;
    font-weight: 600 !important;
    text-align: center !important;
  }

  & table.mat-mdc-table,
  th.mat-mdc-header-cell,
  td.mat-mdc-cell {
    border: 1px solid rgba(0, 0, 0, 0.12);
  }

  & td {
    width: 12.5% !important;
    text-align: center !important;
  }

  & tr.detail-row {
    height: 0;
  }

  & tr.not-expanded-row {
    background: #F4FAFC;
  }

  & .mat-column-expandedDetail {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  & .expand-hide-row {
    overflow: hidden;
    display: flex;

    & .expanded-table {
      flex: 1 !important;
      height: 64px !important;
      display: flex;
      align-items: center;

      &:not(:first-child) {
        justify-content: center;

        & div {
          padding-left: 0;
        }
      }
      &:not(:last-child) {
        border-right: 1px solid rgba(0, 0, 0, 0.12);
      }

      & div {
        padding-left: 64px;
      }
    }
  }


  .footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
  }
  
  .footer-content div {
    flex: 1; /* Ensure that divs within footer are spaced evenly */
    text-align: center; /* Center align text */
    padding: 4px;
  }
  
   td.mat-mdc-footer-cell:first-of-type {
      padding-left: unset;
  }



}

th.mat-mdc-header-cell {
  color: var(--black);
  font-size: 14px;
  font-weight: 600;
}

.table-responsive-learner-metrics {
  & table,
  thead,
  tbody,
  th {
    display: block;
  }

  & th {
    @media (min-width: 1024px) {
      text-align: left !important;
    }
  }
  & th.mat-mdc-header-cell:first-of-type {
    padding-left: 0 !important;
  }

  // & td {
  //   @media (min-width: 1024px) {
  //     width: 14% !important;
  //   }
  // }

  & .mat-column-actions {
    text-align: right !important;
    padding-right: 0px !important;

    @media (min-width: 1024px) {
      padding-right: 24px !important;
    }
  }

  & thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;

    @media (min-width: 1024px) {
      position: static;
      top: auto;
      left: auto;
    }
  }

  & table {
    @media (min-width: 1024px) {
      display: table;
    }
  }

  & thead {
    @media (min-width: 1024px) {
      display: table-header-group;
    }
  }

  & tbody {
    @media (min-width: 1024px) {
      display: table-row-group;
    }
  }

  & tbody tr {
    display: table;
    width: 100%;
    // box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    //   0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    margin: 20px 0;

    @media (min-width: 1024px) {
      width: auto;
      display: table-row;
      box-shadow: none;
      margin: 0;
      border: none;
      font-size: 16px !important;
    }

    // &:nth-of-type(odd) {
    //   background: #eee;
    //    @media (min-width: 1024px) {
    //     background: none;
    //   }
    // }
  }
  & th.mat-mdc-header-cell:last-of-type,
  td.mat-mdc-footer-cell:last-of-type {
    padding-right: 0 !important;
  }

  & td,
  & th {
    font-size: 13px !important;

    @media (min-width: 1024px) {
      font-size: 16px !important;
    }
  }

  & td {
    position: relative;
    padding-left: 55% !important;
    height: 100%;
    display: flex;
    align-items: center;
    padding-top: 5px !important;
    padding-bottom: 15px !important;
    border-bottom-width: 0 !important;
    border-right: 1px solid #ddd;
    border-left: 1px solid #ddd;
    padding-right: 15px !important;
    word-break: break-word;

    &:first-child {
      border-top: 1px solid #ddd;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      padding-top: 10px !important;
      @media (min-width: 1024px) {
        border-top: none;
        border-top-left-radius: none;
        border-top-right-radius: none;
        padding-top: 0px !important;
      }
    }
    &:last-child {
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;
      border-bottom: 1px solid #ddd !important;
      padding-bottom: 10px !important;
      @media (min-width: 1024px) {
        border-bottom: none;
        border-bottom-left-radius: none;
        border-bottom-right-radius: none;
        padding-bottom: 0px !important;
      }
    }

    & p {
      font-size: 13px !important;

      @media (min-width: 1024px) {
        font-size: 16px !important;
      }
    }

    & a {
      font-size: 13px !important;

      @media (min-width: 1024px) {
        font-size: 16px !important;
      }
    }

    @media (min-width: 1024px) {
      display: table-cell;
      padding-left: 0 !important;
      padding-top: 0px !important;
      padding-bottom: 0px !important;
      border-bottom-width: 1px !important;
      border-right: none;
      border-left: none;
      height: 64px;
    }

    &::before {
      position: absolute;
      font-size: 13px !important;
      left: 10px;
      width: 45%;
      padding-right: 10px;
      padding-bottom: 5px;

      @media (min-width: 1024px) {
        font-size: 16px !important;
        content: none !important;
      }
    }

    &:nth-of-type(1):before {
      content: 'First Name';
    }
    &:nth-of-type(2):before {
      content: 'Last Name';
    }
    &:nth-of-type(3):before {
      content: 'Email Address';
    }
    &:nth-of-type(4):before {
      content: 'Training';
    }
    &:nth-of-type(5):before {
      content: 'Version';
    }
    &:nth-of-type(6):before {
      content: 'Added';
    }
    &:nth-of-type(7):before {
      content: 'End';
    }
    &:nth-of-type(8):before {
      content: 'Status';
    }
    &:nth-of-type(9):before {
      content: 'Certificate';
    }
  }
  & th {
    @media (min-width: 1024px) {
      display: table-cell;
    }
  }
}

.table-responsive-history {
& table,
thead,
tbody,
th {
  display: block;
}

& th {
  @media (min-width: 1024px) {
    text-align: left !important;
  }
}
& th.mat-mdc-header-cell:first-of-type {
  padding-left: 0 !important;
}

// & td {
//   @media (min-width: 1024px) {
//     width: 14% !important;
//   }
// }

& .mat-column-actions {
  text-align: right !important;
  padding-right: 0px !important;

  @media (min-width: 1024px) {
    padding-right: 24px !important;
  }
}

& thead tr {
  position: absolute;
  top: -9999px;
  left: -9999px;

  @media (min-width: 1024px) {
    position: static;
    top: auto;
    left: auto;
  }
}

& table {
  @media (min-width: 1024px) {
    display: table;
  }
}

& thead {
  @media (min-width: 1024px) {
    display: table-header-group;
  }
}

& tbody {
  @media (min-width: 1024px) {
    display: table-row-group;
  }
}

& tbody tr {
  display: table;
  width: 100%;
  // box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
  //   0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  margin: 20px 0;

  @media (min-width: 1024px) {
    width: auto;
    display: table-row;
    box-shadow: none;
    margin: 0;
    border: none;
    font-size: 16px !important;
  }

  // &:nth-of-type(odd) {
  //   background: #eee;
  //    @media (min-width: 1024px) {
  //     background: none;
  //   }
  // }
}
& th.mat-mdc-header-cell:last-of-type,
td.mat-mdc-footer-cell:last-of-type {
  padding-right: 0 !important;
}

& td,
& th {
  font-size: 13px !important;

  @media (min-width: 1024px) {
    font-size: 16px !important;
  }
}

& td {
  position: relative;
  padding-left: 55% !important;
  height: 100%;
  display: flex;
  align-items: center;
  padding-top: 5px !important;
  padding-bottom: 15px !important;
  border-bottom-width: 0 !important;
  border-right: 1px solid #ddd;
  border-left: 1px solid #ddd;
  padding-right: 15px !important;
  word-break: break-word;

  &:first-child {
    border-top: 1px solid #ddd;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding-top: 10px !important;
    @media (min-width: 1024px) {
      border-top: none;
      border-top-left-radius: none;
      border-top-right-radius: none;
      padding-top: 0px !important;
    }
  }
  &:last-child {
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom: 1px solid #ddd !important;
    padding-bottom: 10px !important;
    @media (min-width: 1024px) {
      border-bottom: none;
      border-bottom-left-radius: none;
      border-bottom-right-radius: none;
      padding-bottom: 0px !important;
    }
  }

  & p {
    font-size: 13px !important;

    @media (min-width: 1024px) {
      font-size: 16px !important;
    }
  }

  & a {
    font-size: 13px !important;

    @media (min-width: 1024px) {
      font-size: 16px !important;
    }
  }

  @media (min-width: 1024px) {
    display: table-cell;
    padding-left: 0 !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    border-bottom-width: 1px !important;
    border-right: none;
    border-left: none;
    height: 64px;
  }

  &::before {
    position: absolute;
    font-size: 13px !important;
    left: 10px;
    width: 45%;
    padding-right: 10px;
    padding-bottom: 5px;

    @media (min-width: 1024px) {
      font-size: 16px !important;
      content: none !important;
    }
  }

  &:nth-of-type(1):before {
    content: 'Start Date';
    font-weight: 700;
  }
  &:nth-of-type(2):before {
    content: 'Training';
    font-weight: 700;
  }
  &:nth-of-type(3):before {
    content: 'Course';
    font-weight: 700;
  }
  &:nth-of-type(4):before {
    content: 'Status';
    font-weight: 700;
  }
  &:nth-of-type(5):before {
    content: 'Certificate';
    font-weight: 700;
  }
  &:nth-of-type(6):before {
    content: 'Issue Date';
    font-weight: 700;
  }
  &:nth-of-type(7):before {
    content: 'Expire Date';
    font-weight: 700;
  }
  &:nth-of-type(8):before {
    content: '	Retired Date';
    font-weight: 700;
  }
}
& th {
  @media (min-width: 1024px) {
    display: table-cell;
  }
}


td.mat-mdc-cell {
  padding-bottom: 7px !important;
}
}
  
.table-responsive-certificatess {
  & table,
  thead,
  tbody,
  th {
    display: block;
  }

  & th {
    @media (min-width: 992px) {
      text-align: left !important;
    }
  }
  & th.mat-mdc-header-cell:first-of-type {
    padding-left: 0 !important;
  }

  // & td {
  //   @media (min-width: 1024px) {
  //     width: 14% !important;
  //   }
  // }

  & .mat-column-actions {
    text-align: right !important;
    padding-right: 0px !important;

    @media (min-width: 992px) {
      padding-right: 24px !important;
    }
  }

  & thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;

    @media (min-width: 992px) {
      position: static;
      top: auto;
      left: auto;
    }
  }

  & table {
    @media (min-width: 992px) {
      display: table;
    }
  }

  & thead {
    @media (min-width: 992px) {
      display: table-header-group;
    }
  }

  & tbody {
    @media (min-width: 992px) {
      display: table-row-group;
    }
  }

  & tbody tr {
    display: table;
    width: 100%;
    // box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    //   0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    margin: 20px 0;

    @media (min-width: 992px) {
      width: auto;
      display: table-row;
      box-shadow: none;
      margin: 0;
      border: none;
      font-size: 16px !important;
    }

    // &:nth-of-type(odd) {
    //   background: #eee;
    //    @media (min-width: 1024px) {
    //     background: none;
    //   }
    // }
  }
  & th.mat-mdc-header-cell:last-of-type,
  td.mat-mdc-footer-cell:last-of-type {
    padding-right: 0 !important;
  }

  & td,
  & th {
    font-size: 13px !important;

    @media (min-width: 992px) {
      font-size: 16px !important;
    }
  }

  & td {
    position: relative;
    padding-left: 55% !important;
    height: 100%;
    display: flex;
    align-items: center;
    padding-top: 5px !important;
    padding-bottom: 15px !important;
    border-bottom-width: 0 !important;
    border-right: 1px solid #ddd;
    border-left: 1px solid #ddd;
    padding-right: 15px !important;
    word-break: break-word;

    &:first-child {
      border-top: 1px solid #ddd;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      padding-top: 10px !important;
      @media (min-width: 992px) {
        border-top: none;
        border-top-left-radius: none;
        border-top-right-radius: none;
        padding-top: 0px !important;
      }
    }
    &:last-child {
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;
      border-bottom: 1px solid #ddd !important;
      padding-bottom: 10px !important;
      @media (min-width: 992px) {
        border-bottom: none;
        border-bottom-left-radius: none;
        border-bottom-right-radius: none;
        padding-bottom: 0px !important;
      }
    }

    & p {
      font-size: 13px !important;

      @media (min-width: 992px) {
        font-size: 16px !important;
      }
    }

    & a {
      font-size: 13px !important;

      @media (min-width: 992px) {
        font-size: 16px !important;
      }
    }

    @media (min-width: 992px) {
      display: table-cell;
      padding-left: 0 !important;
      padding-top: 0px !important;
      padding-bottom: 0px !important;
      border-bottom-width: 1px !important;
      border-right: none;
      border-left: none;
      height: 64px;
    }

    &::before {
      position: absolute;
      font-size: 13px !important;
      left: 10px;
      width: 45%;
      padding-right: 10px;
      padding-bottom: 5px;

      @media (min-width: 992px) {
        font-size: 16px !important;
        content: none !important;
      }
    }

    &:nth-of-type(1):before {
      content: 'Issue Date';
      font-weight: 700;
    }
    &:nth-of-type(2):before {
      content: 'Training';
      font-weight: 700;
    }
    &:nth-of-type(3):before {
      content: 'Course';
      font-weight: 700;
    }
    &:nth-of-type(4):before {
      content: 'State';
      font-weight: 700;
    }
    &:nth-of-type(5):before {
      content: 'Expire Date';
      font-weight: 700;
    }
    &:nth-of-type(6):before {
      content: 'Certificate';
      font-weight: 700;
    }
   
  }
  & th {
    @media (min-width: 992px) {
      display: table-cell;
    }
  }

  td.mat-mdc-cell {
    padding-bottom: 7px !important;
  }
}

.table-responsive-offsite-certs {
  & table,
  thead,
  tbody,
  th {
    display: block;
  }

  & th {
    @media (min-width: 1024px) {
      text-align: left !important;
    }
  }
  & th.mat-mdc-header-cell:first-of-type {
    padding-left: 0 !important;
  }

  & .mat-column-actions {
    padding-right: 0px !important;

    @media (min-width: 1024px) {
      padding-right: 24px !important;
    }
  }

  & thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;

    @media (min-width: 1024px) {
      position: static;
      top: auto;
      left: auto;
    }
  }

  & table {
    @media (min-width: 1024px) {
      display: table;
    }
  }

  & thead {
    @media (min-width: 1024px) {
      display: table-header-group;
    }
  }

  & tbody {
    @media (min-width: 1024px) {
      display: table-row-group;
    }
  }

  & tbody tr {
    display: table;
    width: 100%;
    // box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    //   0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    margin: 20px 0;

    @media (min-width: 1024px) {
      width: auto;
      display: table-row;
      box-shadow: none;
      margin: 0;
      border: none;
      font-size: 16px !important;
    }

    // &:nth-of-type(odd) {
    //   background: #eee;
    //    @media (min-width: 1024px) {
    //     background: none;
    //   }
    // }
  }
  & th.mat-mdc-header-cell:last-of-type,
  td.mat-mdc-footer-cell:last-of-type {
    padding-right: 0 !important;
  }

  & td,
  & th {
    font-size: 13px !important;

    @media (min-width: 1024px) {
      font-size: 16px !important;
    }
  }

  & td {
    position: relative;
    padding-left: 55% !important;
    height: 100%;
    display: flex;
    align-items: center;
    padding-top: 5px !important;
    padding-bottom: 15px !important;
    border-bottom-width: 0 !important;
    border-right: 1px solid #ddd;
    border-left: 1px solid #ddd;
    padding-right: 15px !important;
    word-break: break-word;

    &:first-child {
      border-top: 1px solid #ddd;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      padding-top: 10px !important;
      @media (min-width: 1024px) {
        border-top: none;
        border-top-left-radius: none;
        border-top-right-radius: none;
        padding-top: 0px !important;
      }
    }
    &:last-child {
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;
      border-bottom: 1px solid #ddd !important;
      padding-bottom: 10px !important;
      @media (min-width: 1024px) {
        border-bottom: none;
        border-bottom-left-radius: none;
        border-bottom-right-radius: none;
        padding-bottom: 0px !important;
      }
    }

    & p {
      font-size: 13px !important;

      @media (min-width: 1024px) {
        font-size: 16px !important;
      }
    }

    & a {
      font-size: 13px !important;

      @media (min-width: 1024px) {
        font-size: 16px !important;
      }
    }

    @media (min-width: 1024px) {
      display: table-cell;
      padding-left: 0 !important;
      padding-top: 0px !important;
      padding-bottom: 0px !important;
      border-bottom-width: 1px !important;
      border-right: none;
      border-left: none;
      height: 64px;
    }

    &::before {
      position: absolute;
      font-size: 13px !important;
      left: 10px;
      width: 45%;
      padding-right: 10px;
      padding-bottom: 5px;

      @media (min-width: 1024px) {
        font-size: 16px !important;
        content: none !important;
      }
    }

  
  }
  & th {
    @media (min-width: 1024px) {
      display: table-cell;
    }
  }
}


.table-responsive-profile-associated {
  & table,
  thead,
  tbody,
  th {
    display: block;
  }

  & th {
    @media (min-width: 1024px) {
      text-align: left !important;
    }
  }
  & th.mat-mdc-header-cell:first-of-type {
    padding-left: 0 !important;
  }

  // & td {
  //   @media (min-width: 1024px) {
  //     width: 14% !important;
  //   }
  // }

  & .mat-column-actions {
    text-align: right !important;
    padding-right: 0px !important;

    @media (min-width: 1024px) {
      padding-right: 24px !important;
    }
  }

  & thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;

    @media (min-width: 1024px) {
      position: static;
      top: auto;
      left: auto;
    }
  }

  & table {
    @media (min-width: 1024px) {
      display: table;
    }
  }

  & thead {
    @media (min-width: 1024px) {
      display: table-header-group;
    }
  }

  & tbody {
    @media (min-width: 1024px) {
      display: table-row-group;
    }
  }

  & tbody tr {
    display: table;
    width: 100%;
    // box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    //   0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    margin: 20px 0;

    @media (min-width: 1024px) {
      width: auto;
      display: table-row;
      box-shadow: none;
      margin: 0;
      border: none;
      font-size: 16px !important;
    }

    // &:nth-of-type(odd) {
    //   background: #eee;
    //    @media (min-width: 1024px) {
    //     background: none;
    //   }
    // }
  }
  & th.mat-mdc-header-cell:last-of-type,
  td.mat-mdc-footer-cell:last-of-type {
    padding-right: 0 !important;
  }

  & td,
  & th {
    font-size: 13px !important;

    @media (min-width: 1024px) {
      font-size: 16px !important;
    }
  }

  & td {
    position: relative;
    padding-left: 55% !important;
    height: 100%;
    display: flex;
    align-items: center;
    padding-top: 5px !important;
    padding-bottom: 15px !important;
    border-bottom-width: 0 !important;
    border-right: 1px solid #ddd;
    border-left: 1px solid #ddd;
    padding-right: 15px !important;
    word-break: break-word;

    &:first-child {
      border-top: 1px solid #ddd;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      padding-top: 10px !important;
      @media (min-width: 1024px) {
        border-top: none;
        border-top-left-radius: none;
        border-top-right-radius: none;
        padding-top: 0px !important;
      }
    }
    &:last-child {
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;
      border-bottom: 1px solid #ddd !important;
      padding-bottom: 10px !important;
      @media (min-width: 1024px) {
        border-bottom: none;
        border-bottom-left-radius: none;
        border-bottom-right-radius: none;
        padding-bottom: 0px !important;
      }
    }

    & p {
      font-size: 13px !important;

      @media (min-width: 1024px) {
        font-size: 16px !important;
      }
    }

    & a {
      font-size: 13px !important;

      @media (min-width: 1024px) {
        font-size: 16px !important;
      }
    }

    @media (min-width: 1024px) {
      display: table-cell;
      padding-left: 0 !important;
      padding-top: 0px !important;
      padding-bottom: 0px !important;
      border-bottom-width: 1px !important;
      border-right: none;
      border-left: none;
      height: 64px;
    }

    &::before {
      position: absolute;
      font-size: 13px !important;
      left: 10px;
      width: 45%;
      padding-right: 10px;
      padding-bottom: 5px;

      @media (min-width: 1024px) {
        font-size: 16px !important;
        content: none !important;
      }
    }

    &:nth-of-type(1):before {
      content: 'Name';
    }
    &:nth-of-type(2):before {
      content: 'Roles';
    }
  }
  & th {
    @media (min-width: 1024px) {
      display: table-cell;
    }
  }
}

.center-theader {
  .mat-sort-header-container {
    justify-content: center;
  }
}

.wrap-anywhere table tbody tr td.mat-mdc-cell {
  overflow-wrap: anywhere;
}

.mat-column-actions {
  height: 64px;
}