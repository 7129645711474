@import './variables.scss';

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-size: 16px;
}

body {
  margin: 0;
  line-height: 1.5;
}

iframe {
  &.course-player-container {
    position: fixed;
    height: 100% !important;
    width: 100%;
    top: 0;
    left: 0;
  }
}

.page-wrapper {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.main-site {
  width: 100%;
  flex-grow: 1;
}

.noti-container {
  display: flex;
  justify-content: center;
  width: 100%;

  .noti {
    display: flex;
    margin: 0 1rem 1.5rem 1rem;
    text-align: center;
    border: #27b494 1px solid;
    border-radius: 0.25rem;
    padding: 1rem;
    width: fit-content;
    align-items: center;

    .mat-icon {
      margin-left: 1rem;
    }
  }
}

.mat-menu-panel {
  max-width: none !important;
  border-radius: 0 !important;
}

.w-min-none {
  min-width: unset !important;
}

.blank-component-mat-drawer-content {
  // min-height: calc(100vh - 113px) !important;
  overflow: auto !important;
}


.break-word {
  word-break: break-word !important;
}

@media screen and (max-width: 767px) {
  _::-webkit-full-page-media,
  _:future,
  :root .safari-only {
    padding-bottom: 30px; //resize
  }
}

.width-55 {
  width: 55%;
}

.min-h-45 {
  height: 70px;
}

.secondary-text-color {
  color: $White;
}
.primary-bg-color {
  background-color: $MRTNavy;
}

.secondary-bg-color{
  background-color: $MRTGreen;
}

.primary-text-color {
  color: $MRTNavy;
}

.bg-color-light {
  background-color: $SuperLightGrey;
}

.bg-color-lightGreen {
  background-color: $lightGreen;
}

.bg-color-lightBlue  {
  background-color: $lightBlue;
}
.text-color-light {
  color: $SuperLightGrey;
}
.table-text-center {
  .mat-sort-header-container,
  td,
  th {
    text-align: center;
    justify-content: center;
  }
}
.mat-dialog-container {
  overflow: hidden !important;
  padding: 0px !important;
  border-radius: 0 !important;
}
.custom-container-1140 {
  max-width: 1140px;
}
.custom-container-1030 {
  max-width: 1030px;
}

.black-backdrop {
  background-color: rgba($Black, 0.8);
}

.mat-drawer-container {
  background-color: $White !important;
}

.border-color-ddd {
  border-color: #dddddd !important;
}
.border-color-c1c1c1 {
  border-color: $MediumGrey !important;
}
.text-color-c1c1c1 {
  color: $MediumGrey !important;
}

.tooltip-bg {
  background-color: $lightGreen !important;
}
.tooltip-border {
  border-color: $lightBlue !important;
}

.z-index-1060 {
  z-index: 1060 !important;
}

.min-w-192 {
  min-width: 192px !important;
}

.bg-fafafa {
  background-color: #fafafa;
}


.main-site {
  width: 100%;
  flex-grow: 1;
}


.noti-container {
  display: flex;
  justify-content: center;
  width: 100%;

  .noti {
    display: flex;
    margin: 0 1rem 1.5rem 1rem;
    text-align: center;
    border: #27b494 1px solid;
    border-radius: 0.25rem;
    padding: 1rem;
    width: fit-content;
    align-items: center;

    .mat-icon {
      margin-left: 1rem;
    }
  }
}

.break-word {
  word-break: break-word !important;
}


.w-min-none {
  min-width: unset !important;
}


.min-h-45 {
  height: 70px;
}

.mat-toolbar-multi-row {
  height: unset !important;
}

.custom-autocomplete-size {
  min-width: 35vw !important;
}

.login-container {
  padding: 50px 0;
}



/* width */
::-webkit-scrollbar {
  width: 5px; /* width of the scrollbar */
}

.mat-mdc-tab.mdc-tab {
  flex-grow: 0 !important;
}

apx-chart > div:nth-child(1) {
  width: 100% !important;
}

#paidCertsChart {
  .apexcharts-pie{
    transform: scale(.85);
    transform-origin: left;
  }

  .apexcharts-legend  {
    position: absolute;
    left: auto;
    top: 0;
    right: 5px;
    bottom: 0;
    display: flex;
    justify-content: center;
  }
}


.apexcharts-legend{
  overflow-x: hidden !important;
}

.apexcharts-legend-series {
  display: flex !important;
  margin-bottom: 1rem !important;
  width: 100%;
  display: flex;
  align-items: center;

  .apexcharts-legend-text {
    width: 100%;
  }
}


.apexcharts-text,
.apexcharts-legend-text {
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 20px !important;
}


#trainingConversionsChart {
  .apexcharts-legend-series{
    width: unset !important;
  }
}

.mat-snack-bar-container {
  background-color: #fff !important;
}

.mt--4 {
  margin-top: -1rem;
}

.site-container-1340 {
  position: relative;
  max-width: 1340px;
  width: 100%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}

.mat-mdc-snack-bar-container .mat-mdc-snackbar-surface {
  background-color: $White !important;
}

.debug {
  &_red {
    border: 4px dashed red;
  }

  &_green {
    border: 4px dashed green;
  }

  &_blue {
    border: 4px dashed blue;
  }

  &_orange {
    border: 4px dashed orange;
  }
}



.cdk-global-overlay-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.cdk-visually-hidden {
  display: none !important;
}

.circular-button {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #29B494;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #29B494;
  cursor: pointer;
  padding: 0;
  outline: none; 
  .dot {
    width: 10px;
    height: 10px;
    background-color: #000033;
    border-radius: 50%;
  }
}
.circular-button.false {
  background-color: transparent;
  border: 2px solid #808080;
  .dot {
    background-color: transparent;
  
  }
}