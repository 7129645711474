
.switch-account {
    & li.active {
      background-color: var(--primary);
      color: var(--white);
      &:hover {
        background-color: var(--primary);
        color: var(--white);
      }
    }
    & li:hover {
      background-color: #eee;
    }
    & li {
      display: block;
      transition: all 0.3s ease-in-out;
    }
  }
  

  
.org-image {
    @screen sm {
      width: 70px;
      height: 70px;
    }
    width: 70px;
    height: 70px;
    display: block;
    object-fit: cover;
    border: 2px solid var(--primary);
    padding: 6px;
    border-radius: 100%;
  }
  
  .org-initials {
    width: 70px;
    height: 70px;
    line-height: 70px;
    text-align: center;
    display: block;
    border: 2px solid #97959c;
    border-radius: 100%;
    color: #97959c;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 2px;
  }

  .profile-img {
    img {
      width: 40px;
      height: 40px;
      border-radius: 1000px;
    }
  }
  

  .invite-user-dialog {
    display: flex;
    justify-content: center;
    
    .mat-dialog-container {
      max-width: 600px !important;
      min-height: 450px !important;
    }
  }

  .btn-spinner {
    width: 20px;
    height: 20px;
    right: 3px;
    border: 3px solid rgba(0, 0, 0, 0.1);
    border-top: 3px solid;
    border-radius: 50%;
    animation: btn-spin 1s linear infinite;
  }
  
  @keyframes btn-spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }