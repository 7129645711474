// Tailwind CSS directives
@tailwind base;
@tailwind components;
@tailwind utilities;


// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
// @use '@angular/material' as mat;
// Plus imports for other components in your app.
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700&display=swap');
@import 'app/styles/variables';

@import './app/styles/main.scss';



//custom styles
@import './app/styles/variables.scss';
@import './app/styles/banner.scss';
@import './app/styles/buttons.scss';
@import './app/styles/footer.scss';
@import './app/styles/mixins.scss';
@import './app/styles/navigation.scss';
@import './app/styles/ngx-guided-tour.scss';
@import './app/styles/side-navigation.scss';
@import './app/styles/tables.scss';
@import './app/styles/typography.scss';
@import './app/styles/tables.scss';
@import './app/styles/utilities.scss';
@import './app/styles/accordion.scss';
@import './app/styles/user-profile.scss';
@import './app/styles/material.scss';
@import './app/styles/modals.scss';
@import './app/styles/recaptcha.scss';
@import './app/styles/import-users.scss';
@import './app/styles/forms.scss';
@import './app/styles/courses.scss';
@import './app/styles/lds-ring.scss';

