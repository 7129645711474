@import './mixins.scss';
@import './variables.scss';
@import './utilities.scss';

.check-box-bg-color.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: $MRTNavy !important;
}

.width-55 {
  width: 55%;
}

.accessibility-icon {
  background-color: $MRTNavy;
  border: 2px solid $White;
  border-radius: 18px;

  /* offset-x | offset-y | blur-radius | spread-radius | color */
  box-shadow: 0 0 0 1px $MRTNavy;
  height: 20px;
  width: 20px;
  line-height: 20px;

  & .mat-icon {
    height: 16px !important;
    width: 16px !important;
    font-size: 16px !important;
  }
}
.accessibility-icon1 {
  background-color: $MRTNavy;
  border: 2px solid $MRTNavy;
  border-radius: 18px;

  /* offset-x | offset-y | blur-radius | spread-radius | color */
  box-shadow: 0 0 0 1px $MRTNavy;
  height: 32px;
  width: 32px;
  line-height: 32px;

  & .mat-icon {
    height: 27px !important;
    width: 27px !important;
    font-size: 27px !important;
  }
}

.auth-header {
  background-color: $SuperLightGrey !important;
}

.register-container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 1rem;
  min-height: calc(100vh - 153px) !important;
}

.custom-register-tabs {
  .mat-tab-header {
    border-bottom: 0 !important;
    overflow: visible !important;
  }
  .mat-card-actions, .mat-card-subtitle, .mat-card-content{
    @media (min-width: 1024px) {
    margin-bottom: 70px !important;
    }
  }
  .mat-tab-label-container {
    overflow: visible !important;

    .mat-ink-bar {
      visibility: hidden !important;
    }

    .mat-tab-labels {
      justify-content: center;

      .mat-tab-label {
        min-width: 170px !important;
        width: 170px !important;
        height: 100px !important;
        padding: 30px !important;
        font-size: 20px !important;
        opacity: 1 !important;
        background-color: $lightGreen !important;
        color: $MRTNavy !important;
        word-wrap: break-word !important;
        overflow: visible !important;
        border-radius: 1rem;
        
        img {
          height: 65px;
        }

        @include respond(phone) {
          padding: 10px !important;
          font-size: 14px !important;
          min-width: 100px !important;
          width: 100px !important;
          height: 80px !important;
        }

        &:not(:last-child) {
          margin-right: 20px !important;
          @include respond(phone) {
            margin-right: 10px !important;
          }
        }

        &.mat-tab-label-active {
          background-color: $MRTNavy !important;
          color: $White !important;

          .round-active-icon {
            opacity: 1;
          }
        }

        .mat-ripple-element {
          opacity: 0 !important;
        }

        .mat-tab-label-content {
          white-space: initial !important;
        }
      }
    }
  }
}

.round-active-icon {
  opacity: 0;
  border-radius: 100%;
  position: absolute;
  top: -8px;
  left: -8px;

  height: 30px;
  width: 30px;
  text-align: center;
  vertical-align: middle;
  line-height: 30px;
}

.custom-tabs {
  padding-top: 0 !important;
  .mat-tab-label-container {
    .mat-tab-labels {
      .mat-tab-label {
        width: 100%;
        height: auto;

        @screen sm {
          height: auto;
          width: 192px;
          padding: 10px;
          white-space: initial !important;
        }

        &.mat-tab-label-active {
          opacity: 1 !important;
          background-color: $lightBlue !important;
        }

        .mat-tab-label-content {
          height: 100%;
          white-space: initial !important;
          flex-direction: column;
          justify-content: space-between;
        }
      }
    }

    .mat-ink-bar {
      display: none !important;
    }
  }

  .mat-tab-body-wrapper {
    overflow: visible !important;

    .mat-tab-body.mat-tab-body-active {
      @screen sm {
        overflow-x: inherit !important;
        overflow-y: inherit !important;
      }
      .mat-tab-body-content {
        @screen sm {
          overflow: inherit !important;
        }
      }
    }
  }
}

.custom-checkbox-bg-color {
  .mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: $MRTNavy !important;
  }
}

.nav-selected {
  a {
    background-color: $MRTNavy !important;
    color: $White !important;

    & .mat-icon {
      color: $White !important;
    }
  }
}

.mat-list-base .mat-list-item .mat-list-item-content {
  padding: 0 0 !important;
  flex-direction: column !important;
}

.tooltip {
  &:hover &__body {
    opacity: 1;
    visibility: visible;
  }

  &__body {
    position: absolute;
    width: 500px;
    padding: 20px;
    border: 2px solid $MediumGrey;
    background-color: $lightGreen;
    border-radius: 4px;
    box-shadow: 0 1px 6px rgb(0 0 0 / 5%);
    left: 100%;
    top: -20px;
    z-index: 1050;
    opacity: 0;
    visibility: hidden;
    display: inline-block;
    transition: all 0.3s ease;

    &::before,
    &::after {
      content: '';
      display: inline-block;
      position: absolute;
    }

    &--right {
      &:before {
        top: 22px;
        right: auto;
        left: -10px;
        border-top: 10px solid transparent;
        border-right: 10px solid rgba($MediumGrey, 1);
        border-bottom: 10px solid transparent;
        border-left: 0px solid rgba($MediumGrey, 1);
      }

      &:after {
        right: auto;
        top: 25px;
        left: -7px;
        border-top: 7px solid transparent;
        border-right: 7px solid $lightGreen;
        border-bottom: 7px solid transparent;
        border-left: 0px solid $lightGreen;
      }
    }
  }
}

.mat-table {
  .mat-row {
    height: 64px;
    font-size: 16px;
    line-height: 1.5;
  }

  .mat-header-row {
    font-size: 16px;
    line-height: 1.5;
    padding-top: 2px !important;
    padding-bottom: 2px !important;

    .mat-sort-header-content {
      font-size: 14px;
      line-height: 1.75;
      font-weight: 600;
      height: 32px;
      color: var(--black);
    }
  }
}

.eye-hidden {
  line-height: 1 !important;
}

.mat-select-mt3 {
  margin-top: 45px !important;
}

.bttn {
  &,
  &:link,
  &:visited {
    display: inline-block !important;
    padding: 12px 40px !important;
    text-decoration: none !important;
    text-transform: capitalize !important;
    color: inherit !important;
    cursor: pointer !important;
    border: 0 !important;
    line-height: normal !important;
    font-family: inherit;
    font-size: 16px;

    @include respond(phone) {
      padding: 10px 20px !important;
    }
  }

  &:hover,
  &:active {
    color: inherit;
    text-decoration: none;
  }

  &.bttn-primary {
    background-color: $MRTNavy !important;
    color: $White !important;
    border: 2px solid $MRTNavy !important;
  }

  &.bttn-secondary {
    background-color: transparent !important;
    color: $MRTNavy !important;
    border: 2px solid $MRTNavy !important;
  }
}

.mat-drawer-side,
.mat-drawer-over {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}

.table-responsive {
  min-height: 0.01%;
  overflow-x: auto;

  width: 100%;
  margin-bottom: 15px;
  overflow-y: hidden;
}

.custom-btn {
  @include respond(phone) {
    white-space: pre-wrap !important;
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
}

.custom-checkbox {
  & .mat-checkbox-layout {
    @include respond(tab-port1) {
      white-space: pre-wrap !important;
    }
  }
}
.bg-fafafa {
  background-color: #fafafa;
}

.box-shadow-custom {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

/*******************
***** Side Bar *****
*******************/
/*
Template Name: Material Pro Admin
Author: Themedesigner
Email: niravjoshi87@gmail.com
File: scss
*/

@import './variables.scss';

/*******************
Main sidebar
******************/

.mat-sidenav {
  width: 100%;
  padding-top: 0px;
  overflow: hidden;
}


#snav {
  background: $lightBlue;
 
  
 
 
   .mat-drawer-inner-container {
     position: absolute;
     width: 100%;
     overflow: visible;
     height: 100%;
     display: flex;
     flex-direction: column;
     justify-content: space-between;
    
    
     @screen md {
       width: $sidebar-width;
       position: fixed;
       height: calc(100% - 100px);
       top: 100px;
       bottom: 0;
       overflow-y: scroll;
 
     }
   }
 
 
   .mat-nav-list .mat-list-base mat-list-item:first-child {
     padding: 0 !important;
 }
 
   .mat-nav-list {
 
     .selected {
 
       .mat-list-item{
           border-left: 3px solid transparent;
           padding: 0;
           background: transparent;
           transition: .3s;
         
         a &:hover {
           background: transparent;
           color: $MRTNavy;
           border-radius: 0;
           font-weight: 700;
           transition: .3s;
         }
 
          &:not(.selected):hover {
           a {
             background: transparent;
             color: #000033;
             border-radius: 0;
             font-weight: 700;
           }
         }
       }
     
 
       .selected {
         border-radius: 0;
       }
 
 
       
       mat-icon:not(.dd-icon) {
         color: $MRTNavy !important;
         background-color: $MRTGreen !important;
         padding: 8px !important;
         border-radius: 10px !important;
         width: unset !important;
         height: 100%;
       }
 
       .dd-icon {
         -webkit-transform: rotate(-180deg);
         transform: rotate(-180deg);
       }
     }
 
     .mat-list-item {
       height: auto;
       padding: 8px;
       border-radius: 10px;

       &:first-child {
         padding-top: 0;
       }  
   
 
   
       .mat-list-item-content {
         display: block;
         padding: 0px 0px;
   
 
       }
   
       a {
         padding: 0 10px;
         margin-bottom: 10px;
         align-items: center;
         display: flex;
         white-space: nowrap;
         color: $DarkGrey;
         margin-bottom: 0;
         font-family: Montserrat;
         font-style: normal;
         font-size: 13px;
         font-weight: 700;
         line-height: 18px;
         
         @screen md{
           justify-content: flex-start;
         }
   
         mat-icon:not(.dd-icon) {
           margin-right: 8px;
         }
   
         mat-icon:not(.dd-icon) {
           color: $DarkGrey;
           background-color: $White;
           padding: 8px;
           border-radius: 10px;
           width: unset;
           height: unset;
         }
   
         .dd-icon {
           font-size: 16px;
           width: 16px;
           transition: 0.2s ease-in;
           margin: 5px 0 5px 5px;
           height: 16px;
         }
       }
   
       &.selected > .mat-list-item-content > a:not(.custom-dropdown),
       &.active > .mat-list-item-content > a:not(.custom-dropdown) {
 
         mat-icon:not(.dd-icon) {
           color: $MRTNavy;
           background-color: $MRTGreen;
           padding: 8px;
           border-radius: 10px;
           width: unset;
         }
   
         span {
           color: $MRTNavy;
           font-weight: 700;
         }
   
         .dd-icon {
           -webkit-transform: rotate(-180deg);
           transform: rotate(-180deg);
         }
       }
   
       &.selected .sub-item .mat-list-item-content a.selected,
       &.active .sub-item .mat-list-item-content a.active {
         color: $MRTNavy;
         border-left: 3px solid $MRTNavy;
         border-radius: 0;
         font-weight: 700;
       }
     }
   }
   
   
   
 
   .app-sidebar > .mat-nav-list > .mat-list-item > .mat-list-item-content {
     padding: 0px 15px;
   }
 
   .mat-nav-list .sub-item {
     display: none;
     margin-top: -15px;
     padding-left: 40px;
 
     a {
       padding-left: 10px;
       height: 35px;
       margin-bottom: 0px;
       margin-top: 5px;
       margin-bottom: 5px;
       border-left: 3px solid transparent;
     }
 
     .child-sub-item {
       display: none;
       margin-top: -15px;
 
       a {
         padding: 0 0 0 55px;
         height: 50px;
         margin-bottom: 0px;
       }
     }
   }
 
   .saperator {
     cursor: text;
     font-size: 13px;
     margin-bottom: 15px;
     margin-top: 25px;
     font-weight: 500;
     white-space: nowrap;
   }
 
   .selected,
   .active {
     span {
       font-weight: 700;
       color: $MRTNavy;
     }
 
     .sub-item {
       display: block;
 
       .active + {
         .child-sub-item {
           display: block;
         }
       }
     }
   }
 }




/*******************
use profile section
******************/

.user-profile {
  position: relative;
  background-size: cover;

  .profile-img {
    width: 50px;
    margin-left: 30px;
    padding: 31px 0;
    border-radius: 100%;

    &::before {
      -webkit-animation: 2.5s blow 0s linear infinite;
      animation: 2.5s blow 0s linear infinite;
      position: absolute;
      content: '';
      width: 50px;
      height: 50px;
      top: 31px;
      margin: 0 auto;
      border-radius: 50%;
      z-index: 0;
    }

    @-webkit-keyframes blow {
      0% {
        box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.1);
        opacity: 1;
        -webkit-transform: scale3d(1, 1, 0.5);
        transform: scale3d(1, 1, 0.5);
      }

      50% {
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.1);
        opacity: 1;
        -webkit-transform: scale3d(1, 1, 0.5);
        transform: scale3d(1, 1, 0.5);
      }

      100% {
        box-shadow: 0 0 0 20px rgba(0, 0, 0, 0.1);
        opacity: 0;
        -webkit-transform: scale3d(1, 1, 0.5);
        transform: scale3d(1, 1, 0.5);
      }
    }

    @keyframes blow {
      0% {
        box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.1);
        opacity: 1;
        -webkit-transform: scale3d(1, 1, 0.5);
        transform: scale3d(1, 1, 0.5);
      }

      50% {
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.1);
        opacity: 1;
        -webkit-transform: scale3d(1, 1, 0.5);
        transform: scale3d(1, 1, 0.5);
      }

      100% {
        box-shadow: 0 0 0 20px rgba(0, 0, 0, 0.1);
        opacity: 0;
        -webkit-transform: scale3d(1, 1, 0.5);
        transform: scale3d(1, 1, 0.5);
      }
    }

    img {
      width: 100%;
      border-radius: 100%;
    }
  }

  .profile-text {
    padding: 5px 0px;
    position: relative;
    cursor: pointer;
    white-space: nowrap;

    > a {
      color: $White !important;
      width: 100%;
      padding: 6px 30px;
      background: rgba(0, 0, 0, 0.5);
      display: block;
      white-space: nowrap;

      &:after {
        position: absolute;
        right: 20px;
        top: 20px;
      }
    }
  }
}

.custom-groups-modal {
  width: calc(100vw);
  height: calc(100vh);

  & .mat-dialog-container {
    overflow: scroll !important;
  }

  & .mat-dialog-content {
    max-height: initial !important;
  }
}
.change-password-dialog {
  min-width: 100vw;
  height: 100vh;

  & .mat-dialog-container {
    border-radius: 0px;

    & app-change-password {
      & .mat-dialog-content {
        & .change-password-content {
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          & h1 {
            width: 400px !important;
          }
          & form {
            min-width: 350px;
            justify-content: center;
          }
        }
      }
  }
}
  @screen md {
    width: 50%;
  }

  @screen sm {
    width: 100%;
  }
}

.reset-psw-max-w {
  max-width: 380px;
  padding-left: auto;
  padding-right: auto;
  margin-left: auto;
  margin-right: auto;
}

.invite-user-dialog {
  width: 100%;

  @screen md {
    width: 50%;
  }

  @screen xl {
    width: 63%;
  }
}

.footer {
  margin-left: 275px !important;

  @include respond(phone) {
    margin-left: 75px !important;
  }
}

.custom-mat-tab {
  & .mat-tab-label.mat-tab-disabled {
    opacity: 1 !important;
  }
}

.custom-mat-icon {
  border: 3px solid;
  line-height: 20px;
  font-size: 19px;
  margin-right: 5px;
  border-radius: 4px;

  &.mat-icon {
    font-weight: 900;
  }
}

.btn-select-training {
  display: flex !important;
  align-items: center;
  justify-content: center;
  background-color: rgb(34, 25, 69, 1);
  padding: 100px;
}
.btn-select-training-course {
  display: flex !important;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: rgb(34, 25, 69, 0.9);
  position: absolute;
  top: 0px;
  left: 0;
  right: 0;
  bottom: 0px;

  @screen sm {
    top: 0px;
    bottom: 0px;
  }
}

.z-index-minus-1 {
  z-index: -1 !important;
}

.course-tab-overlay {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: red;
}

.button-type-none {
  -webkit-appearance: unset !important;
}

.inactive-icon {
  & {
    .st0 {
      fill: #29b494;
    }
    .st1 {
      fill: none;
    }
    .st2 {
      fill: #ffffff;
    }
    .st3 {
      fill: #ee7284;
    }
  }
}

.custom-pagination {
  & {
    gap: 30px;
    padding-right: 25px;
  }
  &-label {
    color: rgba(0, 0, 0, 0.54);
    padding-right: 10px;
    font-size: 13px;
    padding-top: 10px;
  }
  & .mat-paginator-page-size-select {
    margin: 6px 4px 0 4px;
    width: 56px;
  }

  & .mat-paginator-page-size .mat-select-trigger {
    color: rgba(0, 0, 0, 0.54);
  }

  &-buttons {
    color: rgba(0, 0, 0, 0.26);

    & div {
      & span {
        display: inline-block;
        color: rgba(0, 0, 0, 0.54);
        font-size: 13px;
        text-align: center;
        min-width: 20px;
      }
    }
  }
}

.custom-choose-course {
  & .disabled {
    opacity: 0.5;
  }

  & * {
    user-select: none !important;
  }

  & .course-active-element {
    background-color: var(--primary);
    //transition: background-color;

    h5 {
      color: white;
      font-size: 16px;
    }

    .round-active-icon {
      opacity: 1;
    }
  }
}

.back-to-link {
  text-align: right !important;
  margin-top: -30px;
}
.hide {
  display: none;
}
.image-error {
  color: $Red
}


.label-input-file1 {
  padding: 10px 0px;
  // width: 100%;
  display: flex;
}
.m-b-0 {
  margin-bottom: 0px !important;
}
.close.mat-button {
  margin-left: 83%;
  top: 0;
  right: 0;
  padding: 5px;
  line-height: 14px;
  min-width: auto;
  background-color: white;
  font-size: x-large;
}
.button-submit{
  padding-left: 40% !important;
  padding-right:38% !important;
}

// spinner styles Start

.preloader {
  position: absolute;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  z-index: 999999;
  top: 0;
  left: 0;
}

.white-bg {
  background: #fff;
}
.overlay-bg {
  background: #00000080;
}

.spinner {
  width: 40px;
  height: 40px;
  top: 35%;
  position: relative;
  margin: 100px auto;
}

.double-bounce1,
.double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #27b494;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;

  -webkit-animation: sk-bounce 2s infinite ease-in-out;
  animation: sk-bounce 2s infinite ease-in-out;
}

.double-bounce2 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

@-webkit-keyframes sk-bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bounce {
  0%,
  100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}

// spinner styles End

// .input-file-btn {
//   background-color: $White;
//   color: $MRTNavy;
//   padding: 0 20px;
//   border: 2px solid $MRTNavy;
//   text-transform: uppercase;
//   border-radius: 0;
//   font-size: 16px;
//   transition: all 0.3s ease-out;
//   cursor: pointer;
//   text-decoration: none;
//   display: inline-block;

//   &:hover {
//     color: $White;
//     background-color: $MRTNavy;
//   }
// }

.mat-tab-disabled {
  & .mat-tab-label-content {
    height: 100%;
  }
}

// .blank-page-min-height {
//   min-height: calc(100vh - 215px) !important;
  
//   @media (min-width: 992px) {
//     min-height: calc(100vh - 140px) !important;
//   }
// }

.login{
  @screen sm {
    padding-bottom: 90px !important;
  }
  
}
.remove-course-panel-class {
  max-width: 90vw !important;
  @screen sm {
    max-width: 40vw !important;
  }
}

.no-data-overlay-child {
  display: flex !important;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: rgb(34, 25, 69, 0.9);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;

  h2 {
    color: #ffffff;
  }

  z-index: 1000 !important;

  p {
    color: #ffffff;
    padding: 1rem 0;
  }
}

.role-tag {
  padding: 1px 3px 1px 3px;
  border-radius: 2px;
  font-size: 10px;
  width: fit-content;
}

.header-role-tag {
  background-color: #d6d6d6;
  padding: 1px 3px 1px 3px;
  border-radius: 2px;
  font-size: 10px;
  width: fit-content;
}

.no-data-overlay-parent {
  position: relative;
}

.no-data-overlay-child {
  display: flex !important;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: rgb(34, 25, 69, 0.9);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;

  h2 {
    color: #ffffff;
  }

  z-index: 1000 !important;

  p {
    color: #ffffff;
    padding: 1rem 0;
  }
}


.mat-card {
  box-shadow:  none !important;
}