@import 'variables';


.admin-modal-body{

    .mat-slide-toggle {
    
        .mat-slide-toggle-bar {
          background-color: $MediumGrey !important;
          border: 2px solid $DarkGrey !important;
          transition: .3s ease-in-out;
          
          height: 35px !important;
          width: 65px !important;
          border-radius: 25px !important;
      
          .mat-slide-toggle-thumb-container {
            top: 7px;
            left: 5px;
        
            .mat-slide-toggle-thumb {
              background: $DarkGrey !important;
              width: 17px !important;
              height: 17px !important;
              transition: background-color 0.3s ease;
          
            }
          }
        }
      
        &.mat-checked {
          .mat-slide-toggle-bar {
            background-color: $MRTNavy !important;
            border: 2px solid $MRTNavy !important;
      
            .mat-slide-toggle-thumb-container {
              top: 3px;
              transform: translate3d(28px, 0, 0);
        
              .mat-slide-toggle-thumb {
                background: $MRTGreen !important;
                width: 25px !important;
                height: 25px !important;
      
                &::after{
                  content: 'check';
                  font-family: 'Material Icons';
                  font-weight: 600;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                }
              }
            }
          }
        }
      }
      
}


.mdc-text-field {

  background-color: unset !important;
  opacity: 1 !important;

  .mat-mdc-form-field-focus-overlay{
    background-color: unset !important;
  }
}