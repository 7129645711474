@import './variables.scss';

h1,
h2,
h3,
h4,
h5 {
  color: $MRTNavy;
}

body,
input,
span,
textarea,
select,
td,
th,
mat-label
label {
  font-family: Montserrat !important;
}


p, .privacy-list {
  color: $Black;
}

.privacy-list {
  list-style: disc;
  padding-left: 40px;
}


.mat-toolbar h1,
h1 {
  font-family: Montserrat;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  
  @media(min-width: 992px){
    font-size: 42px;
    line-height: 50px;

    &.learner {
      font-size: 30px;
      line-height: 36px; 
    }

  }
}



.mat-toolbar h2,
h2 {
  font-family: Montserrat;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 34px;

  @media(min-width: 992px){
    font-size: 36px;
    line-height: 40px;


    &.learner {
      font-size: 20px;
      line-height: 24px;
    }
    
  }
}

.mat-toolbar h3,
h3 {
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px; 

  @media(min-width: 992px){
    font-size: 28px;
    line-height: 34px;

    &.learner {
      font-size: 16px;
      line-height: 20px; 
    }
    
  }
}


.mat-toolbar h4,
h4 {
 font-size: 16px;
 line-height: 20px;
 font-weight: 600;

 @media(min-width: 992px){
  font-size: 18px;
  line-height: 22px;
 }
}


p, .privacy-list{
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;

  &.bold {
    font-weight: $font-weight-600;
  }

  a {
    color: #000333;
    font-weight: 600;
    line-height: 18px;
    text-decoration-line: underline;
  }

  &.small {
    font-size: 13px;
    font-weight: 500;
    line-height: 16px;
  }

  &.btn-icon-text {
    font-size: 16px;
    line-height: 20px;
  }
}


strong {
  font-weight: $font-weight-700;
}
