// MEDIA QUERY MANAGER
/*

0   - 600px:    Phone
600 - 900px:    Tablet portrait
900 - 1200px:   Tablet landscape
[1200 - 1800px] is where our normal styles apply
1800px +    :   Big Desktop

*/

//////////////////////////
/////////////////////////

/*
$breakpoint argument choices
- phone
- tab-port
- tab-land
- big-desktop
- tab-port1


*/

@mixin respond($breakpoint) {
    @if $breakpoint == phone {
      @media (max-width: 600px) {
        @content;
      }
    }
    @if $breakpoint == tab-port1 {
      @media (max-width: 1000px) {
        @content;
      }
    }
  }
  