.delete-modal {
    & .mat-dialog-container {
      max-width: 500px !important;
  
      overflow: hidden !important;
      padding: 30px !important;
      margin: 0 auto !important;
  
      @include respond(phone) {
        max-width: 100% !important;
      }
    }
  
    & .mat-dialog-content {
      margin: 0 0 !important;
    }
  
    & .mat-dialog-actions {
      margin-bottom: 0 !important;
      padding: 8px 0 0px !important;
    }
  
    & .mat-dialog-container .mat-dialog-content {
      min-width: auto !important;
    }
  
    @include respond(phone) {
      max-width: 90% !important;
      width: 90% !important;
    }
  }
  
  .adminrole-modal {
    & .mat-dialog-container {
      max-width: 500px !important;
  
      overflow: hidden !important;
      padding: 16px !important;
      margin: 0 auto !important;
  
      @include respond(phone) {
        max-width: 100% !important;
      }
    }
  
    & .mat-dialog-content {
      margin: 0 0 !important;
    }
  
    & .mat-dialog-actions {
      margin-bottom: 0 !important;
      padding: 8px 0 0px !important;
    }
  
    & .mat-dialog-container .mat-dialog-content {
      min-width: auto !important;
    }
  
    @include respond(phone) {
      max-width: 90% !important;
      width: 90% !important;
    }
  }
  


.add-course-modal,
.add-module-modal {
  & .mat-dialog-container {
    width: 100vw !important;
    height: 100dvh !important;
    overflow-x: hidden !important;
    overflow-y: auto !important;
    padding: 16px !important;
    margin: 0 auto !important;
  }

  & .mat-dialog-content {
    margin: 0 0 !important;
  }

  & .mat-dialog-actions {
    margin-bottom: 0 !important;
    padding: 8px 0 0px !important;
  }

  & .mat-dialog-container .mat-dialog-content {
    min-width: auto !important;
  }
}

.adminrole-modal {
  & .mat-dialog-container {
    overflow: hidden !important;
    padding: 16px !important;
    margin: 0 auto !important;

    @include respond(phone) {
      max-width: 100% !important;
    }
  }

  & .mat-dialog-content {
    margin: 0 0 !important;
  }

  & .mat-dialog-actions {
    margin-bottom: 0 !important;
    padding: 8px 0 0px !important;
  }

  & .mat-dialog-container .mat-dialog-content {
    min-width: auto !important;
  }

  @include respond(phone) {
    max-width: 90% !important;
    width: 90% !important;
  }
}

.learner-metrics-modal {
  & .mat-dialog-container {
    width: 100vw !important;
    height: 100dvh !important;
    overflow-y: auto !important;
    padding: 16px !important;
    margin: 0 auto !important;
  }

  & .mat-dialog-content {
    margin: 0 0 !important;
  }

  & .mat-dialog-actions {
    margin-bottom: 0 !important;
    padding: 8px 0 0px !important;
  }

  & .mat-dialog-container .mat-dialog-content {
    min-width: auto !important;
  }

  & .content-parent {
    display: flex;
    justify-content: center;
    .content-child{
      width: 50%;
    }
  }
}

.rotated-icon-45 {
  transform: rotate(45deg);
}

.rotated-icon-135 {
  transform: rotate(-135deg);
}

.training-details-column {
  padding-top: .25rem;
  padding-bottom: .25rem;
  display: flex;
  
  .first-item {
    width: 20%;
    align-items: left;
    text-align: left;
  }

  .training-details-label {
    display: flex;
    justify-content: left;
    width: 25%;
    margin-right: 0.5rem;
    font-weight: 500;
  }
}

.dialog-bg-darkgrey {
  background-color: rgba(0, 0, 0, 0.32) !important;
}

.full-screen-modal-panel-class {
  max-width: 100vw !important;
  width: 100vw;
  height: 100%;

  & .mat-dialog-container {
    overflow-y: scroll !important;
    border-radius: 0 !important;
  }

  & .mat-dialog-content {
    max-height: initial !important;
  }
}

.full-screen-modal-medium-mobile-panel-class {
  max-width: 100vw !important;
  width: 100vw;
  height: 100%;


  @media(min-width: 992px) {
    width: 800px;
    height: unset;
    max-width: unset;
  }

  & .mat-dialog-container {
    overflow-y: scroll !important;
    border-radius: 10px !important;
    position: relative;
  }
  
  & .mat-dialog-content {
    max-height: initial !important;
  }
  
}

.admin-modal-body {
  height: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}


.close-modal-button-pos {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.add-message-tile {


  display: flex;
  justify-content: space-between;
  flex-direction: column;


  img {
    width: 100%;
    height: 150px;
  }

  .description {
    height: 75px;
  }
}

.notification-form {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
}