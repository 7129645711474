
@use '@angular/material' as mat;

@import './variables.scss';



$md-primary: (
  50: #e5f6f2,
  100: #bee9df,
  200: #93daca,
  300: #68cbb4,
  400: #47bfa4,
  500: #27b494,
  600: #23ad8c,
  700: #1da481,
  800: #179c77,
  900: #0e8c65,
  A100: #bdffe8,
  A200: #8affd7,
  A400: #57ffc5,
  A700: #3dffbc,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: var(--white),
    700: var(--white),
    800: var(--white),
    900: var(--white),
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

/* For use in src/lib/core/theming/_palette.scss */
$md-secondary: (
  50: #e4e3e9,
  100: #bdbac7,
  200: #918ca2,
  300: #645e7d,
  400: #433c61,
  500: #221945,
  600: #1e163e,
  700: #191236,
  800: #140e2e,
  900: #0c081f,
  A100: #725dff,
  A200: #452aff,
  A400: #1f00f6,
  A700: #1c00dd,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: var(--white),
    400: var(--white),
    500: var(--white),
    600: var(--white),
    700: var(--white),
    800: var(--white),
    900: var(--white),
    A100: var(--white),
    A200: var(--white),
    A400: var(--white),
    A700: var(--white),
  ),
);

/* For use in src/lib/core/theming/_palette.scss */
$md-red: (
  50: #fdeef0,
  100: #fad5da,
  200: #f7b9c2,
  300: #f39ca9,
  400: #f18796,
  500: #ee7284,
  600: #ec6a7c,
  700: #e95f71,
  800: #e75567,
  900: #e24254,
  A100: var(--white),
  A200: var(--white),
  A400: #ffced3,
  A700: #ffb4bc,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: var(--white),
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$primary: mat.m2-define-palette($md-secondary);
$accent: mat.m2-define-palette($md-primary);
$warn: mat.m2-define-palette($md-red);

$custom-typography: mat.m2-define-typography-config(
  $font-family: var(--font-family),
  $body-1: mat.m2-define-typography-level(1rem, 1.5, 400),
);

// @include mat.all-component-typographies($custom-typography);
@include mat.core();

$theme: mat.m2-define-light-theme((
  color: (
    primary: $primary,
    accent: $accent,
    warn: $warn,
  )
));

@include mat.all-component-themes($theme);

.mat-error {
  font-size: 12px !important;
}

.check-box-bg-color.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: $MRTNavy !important;
}

.mat-list-base .mat-list-item .mat-list-item-content {
  padding: 0 0 !important;
  flex-direction: column !important;
}

.mat-select-mt3 {
  margin-top: 45px !important;
}

.mat-drawer-side,
.mat-drawer-over {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}



.mat-toolbar-single-row {
  padding: unset;
}


.cdk-overlay-pane {
  transform: scaleY(1);

  &:not(.mat-mdc-select-panel-above) div.mat-mdc-select-panel {
    margin-top: 0 !important;
  }
}


.mat-mdc-card {
  box-shadow: unset !important;

  &-content {
    &:first-child {
      padding-top: 0 !important;
    }
  }
}

.mat-form-field-label {
  color: #0000008a;
  font-weight: 500;
}

.mdc-tab--active {
  border-bottom: 2px solid black !important;
}

.mdc-tab-indicator {
  display: none !important;
}

.mdc-tab.mat-mdc-tab.cdk-focused,
.mdc-tab.mat-mdc-tab.cdk-mouse-focused {
  background-color: transparent; // Replace with your desired color or remove the highlight
  outline: none; // Remove focus outline
}

.mat-mdc-tab:hover .mdc-tab__ripple::before {
  opacity: 0 !important;
}

.mat-tab:focus {
  background-color: transparent;
  outline: none;

}


.mat-mdc-tab.cdk-focused,
.mat-mdc-tab.cdk-mouse-focused {
  outline: none !important;
  box-shadow: none !important;
  background-color: transparent !important;
}


.mat-mdc-tab.mdc-tab--active {
  background-color: transparent;
}


.mat-mdc-form-field-subscript-wrapper {
  height: 1rem;
}


.mat-mdc-form-field-infix {
  display: flex;
  align-items: flex-end;
  padding: 8px 0 !important;
}

.mat-column-action {
  padding: 16px !important;
}