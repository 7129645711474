.choose-course {
    border-radius: 1rem;
  }
  
  .mobile-choose-course {
    @screen sm {
      &:hover {
        transform: scale(1.1, 1.1);
      }
    }
  }
  
  
  .regular-course-icon {
    display: block;
  }
  
  .contrast-course-icon {
    display: none;
  }

.course-card-grid {
    display: flex;
    flex-wrap: wrap;
    padding: 0.5rem;
    background-color: whitesmoke;
  
    &-item {
      width: 33.33%;
      &:hover {
        .course-card-overlay {
          display: flex !important;
          cursor: pointer;
        }
      }
      .course-card-overlay {
        display: none;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        background-color: #171717A5;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        text-align: center;
        cursor: default;
      }
      
      .course-card-overlay-content {
        color: #ffffff;
        border: 2px solid #ffffff;
        padding: 10px;
      }
  
      &-add {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0.5rem;
        background-color: whitesmoke;
        border: dashed lightgray 2px;
        width: 30%;
        min-height: 100px;
  
        .mat-icon {
          font-weight: 800;
          color: var(--primary);
        }
  
        &:hover {
          border: dashed var(--primary) 2px;
          cursor: pointer;
        }
      }
    }
  }
  
  .course-version-card {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    margin: 0.5rem;
    background-color: white;
  
    &__header {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin-bottom: 1rem;
  
      &__version {
        h3 {
          font-size: 1.75rem;
          font-weight: 900;
          margin: 0.25rem;
          text-align: center;
        }
      }
  
      &__tags {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    
        h4 {
          width: fit-content;
          font-size: 0.66rem;
          font-weight: 500;
          margin: 0.25rem;
          padding: 1px 6px;
          border-radius: 6px;
          border: solid 2px yellow;
  
          &.language {
            &.english {
              border-color: rgb(54, 54, 199);
              color: rgb(54, 54, 199);
            }
            &.spanish {
              border-color: rgb(167, 66, 66);
              color: rgb(167, 66, 66);
            }
          }
    
          &.status {
            &.published {
              border-color: rgb(133, 201, 133);
              color: rgb(133, 201, 133);
            }
            &.unpublished {
              border-color: rgb(202, 140, 41);
              color: rgb(202, 140, 41);
            }
          }
  
          &.retired {
            border-color: rgb(222, 142, 38);
            color: rgb(222, 142, 38);
          }
        }
      }
    }
  
    &__content {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
  
      &__info {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1rem;
  
        &__item {
          display: flex;
          flex-direction: column;
          padding: 0.5rem;
          width: 33.33%;
  
          .label {
            color: rgb(153, 153, 153);
          }
        }
      }
  
      &__modules {
        border-top: lightgray 1px solid;
  
        &__item {
          display: flex;
          align-items: center;
          flex-wrap: nowrap;
          padding: 0.5rem;
          width: 100%;
          border-bottom: lightgray 1px solid;
  
          h2 {
            font-size: 1rem;
            font-weight: 700;
            margin: 0;
            margin-right: 2rem;
          }
  
          h3 {
            font-size: 1rem;
            font-weight: 500;
            margin: 0;
          }
  
          h5 {
            width: fit-content;
            height: fit-content;
            font-size: 0.66rem;
            font-weight: 500;
            margin: 0.25rem;
            margin-left: auto;
            padding: 1px 6px;
            border-radius: 6px;
            border: #23ad8c 1px solid;
          }
        }
      }
    }
  
    &__footer {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-top: 0.5rem;
  
      .mat-icon-button {
        .mat-icon {
          color: var(--primary);
        }
  
        &:hover {
          .mat-icon {
            color: var(--secondary);
          }
        }
  
        &:disabled {
          .mat-icon {
            color: lightgray;
          }
        }
      }
    }
  }
  
  .course-version-card {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    margin: 0.5rem;
    background-color: white;
  
    &__header {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin-bottom: 1rem;
  
      &__version {
        h3 {
          font-size: 1.75rem;
          font-weight: 900;
          margin: 0.25rem;
          text-align: center;
        }
      }
  
      &__tags {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    
        h4 {
          width: fit-content;
          font-size: 0.66rem;
          font-weight: 500;
          margin: 0.25rem;
          padding: 1px 6px;
          border-radius: 6px;
          border: solid 2px yellow;
  
          &.language {
            &.english {
              border-color: rgb(54, 54, 199);
              color: rgb(54, 54, 199);
            }
            &.spanish {
              border-color: rgb(167, 66, 66);
              color: rgb(167, 66, 66);
            }
          }
    
          &.status {
            &.published {
              border-color: rgb(133, 201, 133);
              color: rgb(133, 201, 133);
            }
            &.unpublished {
              border-color: rgb(202, 140, 41);
              color: rgb(202, 140, 41);
            }
          }
  
          &.retired {
            border-color: rgb(222, 142, 38);
            color: rgb(222, 142, 38);
          }
        }
      }
    }
  
    &__content {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
  
      &__info {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1rem;
  
        &__item {
          display: flex;
          flex-direction: column;
          padding: 0.5rem;
          width: 33.33%;
  
          .label {
            color: rgb(153, 153, 153);
          }
        }
      }
  
      &__modules {
        border-top: lightgray 1px solid;
  
        &__item {
          display: flex;
          align-items: center;
          flex-wrap: nowrap;
          padding: 0.5rem;
          width: 100%;
          border-bottom: lightgray 1px solid;
  
          h2 {
            font-size: 1rem;
            font-weight: 700;
            margin: 0;
            margin-right: 2rem;
          }
  
          h3 {
            font-size: 1rem;
            font-weight: 500;
            margin: 0;
          }
  
          h5 {
            width: fit-content;
            height: fit-content;
            font-size: 0.66rem;
            font-weight: 500;
            margin: 0.25rem;
            margin-left: auto;
            padding: 1px 6px;
            border-radius: 6px;
            border: #23ad8c 1px solid;
          }
        }
      }
    }
  
    &__footer {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-top: 0.5rem;
  
      .mat-icon-button {
        .mat-icon {
          color: var(--primary);
        }
  
        &:hover {
          .mat-icon {
            color: var(--secondary);
          }
        }
  
        &:disabled {
          .mat-icon {
            color: lightgray;
          }
        }
      }
    }
  }

  .delete {
    &__mat-dialog {
      display: flex;
      flex-direction: column;
      height: 100%;
      position: relative;
    }
  
    &__inner {
      display: flex;
      flex-direction: column;
      height: 100%;
      min-height: 200px !important;
      justify-content: space-between;
      align-items: center;
    }
  
    &__close {
      position: absolute;
      top: -15px;
      right: -15px;
      color: #5e5e5e;
      cursor: pointer;
    }
  
    &__icon {
      width: 60px;
      height: 60px;
      line-height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 2px solid var(--primary);
      border-radius: 100%;
      margin-bottom: 10px;
  
      & .mat-icon {
        width: 34px !important;
        height: 34px !important;
        font-size: 34px !important;
        color: var(--primary) !important;
      }
    }
  
    &__header {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
    }
  
    &__heading {
      text-align: center;
    }
  
    &__body {
      font-size: 14px;
      color: #5e5e5e;
      text-align: center;
      margin-bottom: 10px;
    }
  
    &__footer {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 15px;
  
      @include respond(phone) {
        flex-direction: column;
      }
    }
  
    &__cancel,
    &__submit {
      &,
      &:link,
      &:visited {
        cursor: pointer;
        display: flex;
        padding: 10px 50px;
        color: #ffffff;
        border-radius: 4px;
        border: 2px solid var(--primary) !important;
        background: transparent;
        text-decoration: none;
        font-size: 15px;
      }
  
      &:hover,
      &:active {
        text-decoration: none;
        color: #ffffff;
      }
    }
  
    &__cancel {
      border: 2px solid var(--primary);
      color: var(--white) !important;
      background-color: var(--primary);
      align-items: center;
      justify-content: center;
      font-weight: 700;
  
      &:hover {
        color: var(--primary) !important;
        background-color: transparent;
        border: 2px solid var(--primary);
      }
    }
  
    &__submit {
      border: 2px solid var(--primary);
      color: var(--primary) !important;
      background-color: transparent;
      align-items: center;
      justify-content: center;
      font-weight: 700;
  
      &:hover {
        color: var(--white) !important;
        background-color: var(--primary);
      }
    }
  }

  
.custom-tooltip {
  .mdc-tooltip__surface {
    background-color: #e1f4f0;
    border: solid var(--primary) 1px;
    padding: 12px;
    width: 200px;
  }
}

.learner-resources {
  tr.mat-header-row {
    height: 8px !important;
  }
}


.custom-tooltip {
  .mdc-tooltip__surface {
    background-color: #e1f4f0;
    border: solid var(--primary) 1px;
    padding: 12px;
    width: 200px;
  }
}


.role-tag {
  background-color: #d6d6d6;
  padding: 1px 3px 1px 3px;
  border-radius: 2px;
  font-size: 10px;
  width: fit-content;
}

.content-container {
  margin: 1rem;
}

.public-user-association {
  margin-top: -4px;
  a {
    font-size: 12px !important;
    margin-left: 155px;
    position: relative;
    z-index: 1;
    bottom: 2px;
  }
  mat-form-field {
    margin-top: -20px;
  }
}

.grid-rows-columns {
  grid-template-columns: repeat(auto-fill, 350px);
  grid-auto-rows: 150px; 
  grid-auto-rows: minmax(150px, 1fr) !important;
}