@import './variables.scss';

.custom-accordion-training {
  &.mat-accordion {
    & .mat-expansion-panel-body {
      padding: 0;
    }

    & .mat-expansion-panel {
      box-shadow: none;
      margin: 0;
      border-top: 1px solid lightgray;

      &:last-of-type {
        border-bottom: 1px solid lightgray;
      }

      & .mat-expansion-indicator::after {
        border-color: var(--primary);
      }

      &.mat-expanded {
        background-color: #f4fafc;

        & .mat-expansion-panel-header-title {
          color: var(--primary);
        }

        & .mat-expansion-indicator::after {
          border-color: var(--primary);
        }
      }
    }

    & .mat-expansion-panel-header-title {
      color: var(--primary);
    }

    & .mat-expansion-panel-header {
      height: unset;
      font-size: 18px;
      font-weight: 600;
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
}


.course-grid-accordion {
  &.mat-accordion {
    .mat-expansion-panel {
      .mat-expansion-panel-header {
        height: 110px;

        .mat-content {
          height: 100%;
          padding-top: 1rem;

          .header-icon {
            height: 44px;
            width: 44px;
            margin-right: 1rem;
          }

          h1 {
            font-size: 1.5rem;
            font-weight: 700;
            margin: 0;
          }

          h2 {
            font-size: 1rem;
            font-weight: 500;
            margin: 0;
            line-height: 1rem;
    
            .mat-icon {
              height: 18px !important;
              width: 18px !important;
              font-size: 18px !important;
              color: lightgray !important;
            }
          }

          .info {
            display: flex;
            align-items: center;
            gap: 2rem;
  
            p {
              margin: 0;
              opacity: 0.6;
            }
          }
  
          .tags {
            display: flex;

            h4 {
              width: fit-content;
              font-size: 0.66rem;
              font-weight: 500;
              margin: 0.25rem;
              padding: 1px 6px;
              border-radius: 6px;
              color: white;
              height: fit-content;
        
              &.training-type {
                &.child {
                  background-color: rgb(133, 133, 201);
                }
                &.elder {
                  background-color: rgb(167, 66, 66);
                }
                &.harassment {
                  background-color: rgb(150, 100, 134);
                }
                &.implicit-Bias {
                  background-color: rgb(102, 178, 255);
                }
                &.unknown {
                  background-color: rgb(0, 0, 0);
                }
              }
        
              &.status {
                &.published {
                  background-color: rgb(133, 201, 133);
                }
                &.unpublished {
                  background-color: rgb(202, 140, 41);
                }
              }
        
              &.state {
                &.single {
                  background-color: rgb(61, 158, 164);
                }
                &.multi {
                  background-color: rgb(66, 96, 187);
                }
              }

              &.paid {
                background-color: rgb(133, 201, 133);
              }

              &.ce {
                background-color: rgb(201, 133, 183);
              }
            }
          }
        }
      }

      .mat-expansion-panel-body {
        padding: 0;
      }

      .mat-expansion-panel-spacing {
        margin: 0;
      }
    }
  }
}

.expanded-panel-color {
  background-color: #f4fafc !important;
}


.learner-metrics-accordion {
  .mat-expansion-panel {
    box-shadow:  none !important;
    border-top: 1px solid lightgray;
    border-radius: 0 !important;

    .mat-expansion-panel-header {
      padding: 35px 0;


      .mat-expansion-indicator::after {
        border-color: $DarkGrey;
        margin-right: 7px;
        margin-left: 7px;
     }
    }

    &:last-of-type {
      border-bottom: 1px solid lightgray;
    }
  }

}