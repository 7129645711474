@import './mixins.scss';
@import './variables.scss';


/////////////////////////////////
///          Buttons          ///
/////////////////////////////////

.btn-secondary {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  border-radius: 10px;
  border: 2px solid $MRTGreen;
  color: $MRTNavy;
  background: $MRTGreen;
  text-align: center;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;

  &.small {
    padding: 8px 10px;
    font-size: 14px;
  }

  &:disabled {
    opacity: 0.4;
  }

  &:hover {
    border: 2px solid $MRTNavy;
    background: $MRTNavy;
    color: $White;
  }

  &.medium {
    padding: 10px 15px;
    font-size: 16px;
  }

  &.large {
    padding: 15px 30px;
    font-size: 18px;
  }

  &.size-fixed,
  &.fixed-size {
    display: flex;
    width: 200px;
    height: 45px;
  }
}

.btn-primary {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  border-radius: 10px;
  border: 2px solid $MRTNavy;
  color: $White;
  background: $MRTNavy;
  text-align: center;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;

  &.small {
    padding: 8px 10px;
    font-size: 14px;
  }

  &:disabled {
    opacity: 0.4;
  }

  &:hover {
    border: 2px solid $MRTGreen;
    background: $MRTGreen;
    color: $MRTNavy;
  }

  &.medium {
    padding: 10px 15px;
    font-size: 16px;
  }

  &.large {
    padding: 15px 30px;
    font-size: 18px;
  }
&.size-fixed,
  &.fixed-size {
    display: flex;
    width: 200px;
    height: 45px;
  }
}

.btn-light {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  border-radius: 10px;
  border: 2px solid $White;
  color: $MRTNavy;
  background: $White;
  text-align: center;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;

  &.small {
    padding: 8px 10px;
    font-size: 14px;
  }

  &:disabled {
    opacity: 0.4;
  }

  &:hover {
    border: 2px solid $White;
    background: $MRTNavy;
    color: $White;
  }

  &.medium {
    padding: 10px 15px;
    font-size: 16px;
  }

  &.large {
    padding: 15px 30px;
    font-size: 18px;
  }
&.size-fixed,
  &.fixed-size {
    display: flex;
    width: 200px;
    height: 45px;
  }
}

.btn-primary-outlined {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  border-radius: 10px;
  border: 2px solid $MRTNavy;
  color: $MRTNavy;
  background: transparent;
  text-align: center;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;

  &.small {
    padding: 8px 10px;
    font-size: 14px;
  }

  &:disabled {
    opacity: 0.4;
  }

  &:hover {
    border: 2px solid $MRTNavy;
    background: $MRTNavy;
    color: $White;
  }

  &.medium {
    padding: 10px 15px;
    font-size: 16px;
  }

  &.large {
    padding: 15px 30px;
    font-size: 18px;
  }

  &.size-fixed,
  &.fixed-size {
    display: flex;
    width: 200px;
    height: 45px;
  }

  p {
    font-weight: unset;
    color: unset
  }
}

.btn-secondary-outlined {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  border-radius: 10px;
  border: 2px solid $MRTGreen;
  color: $MRTGreen;
  background: transparent;
  text-align: center;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;

  &.small {
    padding: 8px 10px;
    font-size: 14px;
  }

  &:disabled {
    opacity: 0.4;
  }

  &:hover {
    border: 2px solid $MRTGreen;
    background: $MRTGreen;
    color: $MRTNavy;
  }

  &.medium {
    padding: 10px 15px;
    font-size: 16px;
  }

  &.large {
    padding: 15px 30px;
    font-size: 18px;
  }
&.size-fixed,
  &.fixed-size {
    display: flex;
    width: 200px;
    height: 45px;
  }
}



/////////////////////////////////
///           Links           ///
/////////////////////////////////
.primary-link {
    h1, h2, h3, h4, h5 {
    color: unset;
  }

  color: $MRTNavy !important;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;


 
  &:hover,
  &:active {
    color: $MRTNavy !important;
    text-decoration: underline !important;

    .svg-icon {
      svg {

        &.filled {
          display: block;
        }
    
        &.open{
          display: none;
        }
      }
    }

  }

  &.underlined {
    text-decoration: underline !important;
  }

  &.capitalize {
    text-transform: capitalize;
  }

  &:disabled {
    color: $MediumGrey !important;
  }


  .svg-icon {
    svg {
      width: 1rem;
      margin-right: 7px;
  
      &.filled {
        display: none;
      }
  
      &.open{
        display: block;
      }

    }
  }
}


.label-link {
  h1, h2, h3, h4, h5 {
    color: unset;
  }

  color: #0000008a !important;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;


 
  &:hover,
  &:active {
    color: $MRTGreen !important;
    text-decoration: underline !important;

    .svg-icon {
      svg {

        &.filled {
          display: block;
        }
    
        &.open{
          display: none;
        }
      }
    }

  }

  &.underlined {
    text-decoration: underline !important;
  }

  &.capitalize {
    text-transform: capitalize;
  }

  &:disabled {
    color: $SuperLightGrey !important;
  }


  .svg-icon {
    svg {
      width: 1rem;
      margin-right: 7px;
  
      &.filled {
        display: none;
      }
  
      &.open{
        display: block;
      }

    }
  }
}

.btn-action {
  color: $MRTNavy !important;

  &:disabled {
    color: $MediumGrey !important;
  }

  &:hover,
  &:active {
    color: $MRTGreen !important;

    &:disabled {
      color: $MediumGrey !important;
    }
  }
}


.exit-btn {
  border: 0 !important;
  box-shadow: none !important;

  mat-icon,
  p {
    color: $MRTNavy !important;
  }

  
  &:disabled {
    color: $MediumGrey !important;
  }

  &:hover,
  &:active {
    mat-icon,
    p {
      color: $MRTGreen !important;
    }

    &:disabled {
      color: $MediumGrey !important;
    }
  }
}

/////////////////////////////////
///           Icons           ///
/////////////////////////////////
.check-icon {
  color: unset;
  background-color: unset;
  @apply text-primary bg-white;
  @apply border-primary border-2;

  &.mat-icon {
    height: 32px !important;
    width: 32px !important;
  }
}


.check-icon-text-filled {
  @apply text-primary bg-secondary;
  @apply border-secondary border-2;
  @apply hover:bg-secondary hover:border-secondary hover:text-primary;
  &.round-active-icon-text  {
    height: 32px !important;
    width: 32px !important;
  }
}

.check-icon-text-outline {
  @apply text-secondary bg-transparent;
  @apply border-secondary border-2;
  @apply hover:bg-transparent hover:border-secondary hover:text-secondary;
  &.round-active-icon-text {
    height: 32px !important;
    width: 32px !important;
  }
}

