.env-banner {
  &.env-banner-dev {
    background-color: #e24254;
  }

  &.env-banner-test {
    background-color: coral;
  }

  color: white;
  height: 32px;
  font-size: 16px;
  text-align: center;
  z-index: 5000;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.banner-message {
  padding: 12px 16px 12px 16px;
  background-color: #e7f6f3;
  color: white;
  min-height: 64px;
  font-size: 16px;
  font-weight: 600;
  z-index: 5000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: #a0e2d5 solid 1px;
}

.reporting-db-banner {
  background-color: #e24254;
  text-align: center;
  color: white;
  height: 32px;
  font-size: 16px;
  text-align: center;
  z-index: 5000;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}