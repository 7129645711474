ngx-guided-tour {
    & .tour-step .tour-buttons .back-button {
      display: none;
    }
    & .tour-step .tour-buttons {
      display: flex !important;
      flex-direction: column-reverse !important;
      justify-content: flex-end !important;
  
      @media (min-width: 992px) {
        flex-direction: row-reverse !important;
      }
    }
    & .tour-step .tour-buttons .next-button {
      width: 100% !important;
      padding: 10px !important;
      background-color: var(--primary) !important;
      margin-right: 24px !important;
      font-weight: 700 !important;
      font-size: 16px !important;
  
      &:hover {
        background-color: var(--secondary) !important;
      }
  
      @media (min-width: 992px) {
        width: 40% !important;
      }
    }
    & .tour-step .tour-buttons button.link-button {
      font-size: 16px !important;
      text-decoration: underline !important;
      font-weight: 400 !important;
      color: var(--primary);
      font-weight: 500 !important;
      &:hover {
        color: var(--secondary) !important;
      }
    }
    & .tour-step .tour-arrow::before {
      display: none !important;
    }
    & .tour-block {
      border: 2px solid var(--accent) !important;
      box-shadow: none !important;
    }
    & .tour-step .tour-content {
      font-size: 13px !important;
      font-weight: 500 !important;
      @screen sm {
        font-size: 15px !important;
      }
      & p {
        font-size: 13px !important;
        font-weight: 500 !important;
        @screen sm {
          font-size: 15px !important;
        }
      }
    }
    & .guided-tour-spotlight-overlay {
      border: 2px solid var(--accent) !important;
    }
  
    & .skip-button {
      margin-top: 1rem;
  
      @media (min-width: 992px) {
        margin-top: unset;
      }
    }
  
    & .tour-title {
      line-height: 24px;
  
      @media (min-width: 992px) {
        line-height: unset;
      }
    }
  
    & .tour-bottom-right {
      @media (min-width: 320px) and (max-width: 640px) {
        width: 100% !important;
        left: 0px !important;
        margin: 0px !important;
      }
      & .tour-block .tour-buttons .skip-button.link-button {
        display: none !important;
      }
      & .tour-block .tour-buttons .next-button {
        width: 45% !important;
      }
    }
  }