@import './variables.scss';


.sidenav-height {
    height: calc(100vh - 64px);
  }
  
.main-site {
  .mat-drawer-content {
    // position: absolute !important;
    width: 100%;
  
    @screen md {
      width: calc(100% - $sidebar-width);
    }


    &.minitoggle {
      @screen md {
        width: calc(100% - 75px);
      }
    }
  }
}

.version {
  padding: 12px;
  position: absolute;
  bottom: 0;
  right: 0;
}

#snav {
  background-color: $lightBlue;
  position: fixed;

  .mat-expansion-panel-content {
    display: block;
  } 


  // &.minisidebar{

  //  .mat-drawer-inner-container {
  //     width: 75px;
  // }


  //   .mat-expansion-panel-content {
  //     display: none;
  //   }


  //   .sidenav-link,
  //   .sidenav-dropdown {
  //     padding: 0;

  //     span {
  //       display: none;
  //     }
  //   } 


  // }


  .nav-link-wrapper{
    &:nth-child(n + 2) {
      margin-top: 1.25rem;
    }
  }

  .sidenav-link {
    padding: 1rem 25px;

    mat-icon {
      color: $DarkGrey;
      padding: 8px;
      background-color: $White;
      border-radius: 10px !important;
      width: unset !important;
      height: unset !important;
    }

    span {
      font-weight: 700;
      color: $DarkGrey;
      font-size: 13px;
      line-height: 18px;
    }

    &:hover {
      span {
       color: $MRTNavy;
      }
    }

    &.selected {
      span {
        color: $MRTNavy;
      }

      mat-icon {
        color: $MRTNavy !important;
        background-color: $MRTGreen;
      }
    }
  }

  .sidenav-dropdown {
    margin-left: 40px;
    margin-bottom: 0px;
    
    a {
      padding: 10px 0;
      padding-left: 10px;
      border-left: 3px solid transparent;
      color: $DarkGrey;
      margin-bottom: 0;
      font-family: Montserrat;
      font-style: normal;
      font-size: 13px;
      font-weight: 700;
      line-height: 18px;
      margin-top: 5px;
      margin-bottom: 5px;

      &:hover {
        color: $MRTNavy;
      }
    }

    .selected {
      color: $DarkGrey;
      border-radius: 0;
      font-weight: 700;
      display: flex;
      align-items: center;
      color: $MRTNavy;
      border-left: 3px solid $MRTNavy;
    }
  }

 .mat-expansion-panel {
    background-color: transparent !important;
    box-shadow: none;

    .mat-expansion-panel-header {
      span {
        font-weight: 700;
        color: $DarkGrey;
        font-size: 13px;
        line-height: 18px;
      }
    
      mat-icon {
        color: $DarkGrey;
        padding: 8px;
        background-color: $White;
        border-radius: 10px !important;
        width: unset !important;
        height: unset !important;
      }
  
      &.mat-expanded {
        span {
          color: $MRTNavy;
        }

        mat-icon:not(.dd-icon) {
          color: $MRTNavy !important;
          background-color: $MRTGreen;
        }
    
        .dd-icon {
          -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
        }
      }
    }
  }
}


.app-sidebar > .mat-nav-list > .mat-list-item > .mat-list-item-content {
  padding: 0px 15px;
}

