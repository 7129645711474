@import './variables.scss';

.header-container {
  position: sticky;
  top: 0;
  z-index: 1000;
}

.close-icon {
  width: 24px;
}

.header-logo {
  & img {
    width: 163px;
    max-width: 100%;
  }
}



.header-logo-icon {
  & img {
    height: 38px;
    max-width: 100%;
  }
}

.menu-icon {
  .mat-icon {
    font-size: 32px;
    height: 32px;
    width: 32px;
  }
}


.header {
  width: 100%;
  padding-left: 2rem;
  justify-content: space-between;

  .mat-slide-toggle-thumb-container {
    top: 1px !important;
    font-size: 16px !important;
  }

  .mat-slide-toggle-bar {
    border: 2px solid #000;
    background-color: transparent !important;
    width: 30px !important;
  }

  .mat-slide-toggle.mat-checked {
    .mat-slide-toggle-bar {
      background-color: #e24254 !important;
      border: 2px solid #e24254;
    }

    .mat-slide-toggle-thumb {
      background-color: #fff !important;
    }
  
  }


  .mat-slide-toggle-thumb {
    background-color: #000 !important;

    height: 8px !important;
    width: 8px !important;
  }

}


.header-toolbar {
  background-color: $White !important;
  border-bottom: 1px solid $MediumGrey;
}


.mymegamenu {
  margin-top: 13px;
}